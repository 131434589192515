* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
}

/* doctor profile css */
.profile-picture-container {
  position: relative;
  display: inline-block;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.doctor-profile-image  {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-button {
  position: absolute;
  bottom: 20px;
  right: 50px;
  background: #fff;
  color: rgb(62, 163, 163);
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content1 {
  background: white;
  border-radius: 12px;
  width: 400px;
  overflow: hidden;
}

.modal-title {
  text-align: center;
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
 font-size: 20px;
}

.modal-options {
  display: flex;
  flex-direction: column;
}

.modal-option {
  padding: 15px;
  text-align: center;
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  color: #0095f6;
}

.modal-option.remove {
  color: #ed4956;
}

.modal-option.cancel {
  color: #262626;
}

.modal-option:hover {
  background: #f9f9f9;
}



.doctor_name{
  color: #416dba;
  font-size: 20px;
  font-weight: inherit;
  font-weight: 500;
}
.search--notification--profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  
}

.notification--profile {
  display: flex;
  align-items: center;
}

.picon {
  margin-left: 20px;
  font-size: 1.1rem;
  padding: 5px;
  border-radius: 5px;
}

.lock {
  color: rgb(59, 213, 213);
  background-color: rgb(106, 168, 243);
}

.bell {
  color: #f1d243;
  background-color: #fff;
}

.chat {
  color: #70d7a5;
  background-color: #fff;
}

.search {
  background-color: #dfe5f1;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.icon-3 .ri-calendar-2-line{
  color: rgb(136, 127, 238);
  font-size: 20px;
}
.search input {
  background-color: transparent;
  outline: none;
  border: none;
  text-indent: 15px;
  width: 100%;
  cursor: pointer;
}

.search button {
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search button i {
  font-size: 1.1rem;
  color: rgb(58, 173, 173);
}

.sidebar {
  position: fixed;
  width: 240px;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 1000;
  left: -240px;
  border-right: 2px solid #f1f1f1;
  cursor: pointer;
}

.sidebar.open {
  left: 0;
  z-index: 1000;
}


.profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  position: relative;
  width: 100%;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ccc;
  position: relative;

}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.default-picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 14px;

}

.camera-button {
  position: absolute;
  top: 100px;
  right: 54px;
  background-color: #fff;
  color: rgb(69, 180, 180);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
} 

/* .profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ccc;


}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.default-picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 14px;
 
} */

/* .upload-button {
  position: absolute;
  top: 100px;
  right: 54px;
  background-color: rgb(188, 184, 184);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: rgb(58, 173, 173);
}  */





.sidebar nav a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #353535;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sidebar nav a:hover,
.sidebar nav a.active {
  background-color: #f0f0f0;
  color: rgb(58, 173, 173);
  border-left: 5px solid rgb(58, 173, 173);
}

.sidebar nav a span:first-child {
  margin-right: 15px;
  font-size: 20px;
}

.main-content {
  margin-left: 0;
  transition: margin-left 0.3s ease;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f7f4f4;
}

.toggle-sidebar {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #59c7c7;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #dfe5f1;
  border-radius: 20px;
  padding: 5px 15px;
}

.search-container input {
  border: none;
  background: transparent;
  padding: 5px;
  outline: none;
}

.search-container button {
  background: none;
  border: none;
  cursor: pointer;
}

.notification-profile {
  display: flex;
  align-items: center;
}

.picon {
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
}
 .icon-0 {
    color: rgb(58, 173, 173);
  }
  .icon-1 {
    color: rgb(109, 189, 247);
  }
  .icon-2 {
    color: #7792ff;
  }
  .icon-3 {
    color: rgb(136, 127, 238);
  }
  .icon-4 {
    color: #85ade3;
  }
  .icon-5 {
    color: #e36ac8;
  }
  .icon-6 {
    color: #70d7a5;
  }
  .icon-7 {
    color: #5f5ce0;
  }
  .icon-8 {
    color: #e86786;
  }
  .icon-9 {
    color: #2c8de1;
  }
  
.bell {
  color: #f1d243;
}

.chat {
  color: #70d7a5;
}



.close-sidebar {
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #59c7c7;
  cursor: pointer;
  padding: 8px;
  display: none; /* Hidden by default */
  align-items: center;
  justify-content: center;
  z-index: 1001;
  transition: color 0.3s ease;
}

/* Show close button only on screens 1024px or smaller */
@media (max-width: 1024px) {
  .search-container {
    max-width: 200px;
  }
  .close-sidebar {
    display: flex;
  }
}

.close-sidebar:hover {
  color: #416dba;
}

.sidebar {
  position: fixed;
  width: 240px;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 1000;
  left: -240px;
  border-right: 2px solid #f1f1f1;
  cursor: pointer;
}

.sidebar.open {
  left: 0;
}


/* Rest of your existing CSS remains the same */


@media (min-width: 1025px) {
  .sidebar {
    left: 0;
  }

  .main-content {
    margin-left: 240px;
  }

  .toggle-sidebar {
    display: none;
  }
  .search--notification--profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  
    width: 200%;
  }
}


@media (max-width: 768px) {
  header {
    flex-wrap: wrap;
  }

  .search-container {
    order: 3;
    width: 100%;
    margin-top: 10px;
  }

  .notification-profile {
    margin-left: auto;
  }
}
































































/*


import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCamera, FaFilePrescription } from "react-icons/fa";
import "../css/drdashheader.css";

const Drdashheader = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [doctorName, setDoctorName] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const savedImage = localStorage.getItem("profilePicture");
    if (savedImage) {
      setPreview(savedImage);
    }

    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setPreview(reader.result);
        localStorage.setItem("profilePicture", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/dashboard");
  };

  return (
    <>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="profile-picture-container">
          <div className="profile-picture">
            {preview ? (
              <img src={preview} alt="Profile" />
            ) : (
              <div className="default-picture">No Image</div>
            )}
          </div>
          <input
            type="file"
            accept="image/"
            onChange={handleImageUpload}
            style={{ display: "none" }}
            id="fileInput"
          />
          <label htmlFor="fileInput" className="upload-button">
            <FaCamera />
          </label>
        </div>
        <nav>
          <a className="active" onClick={() => navigate("/drdashboard")}>
            <span className="icon-0">
              <i className="ri-layout-grid-line"></i>
            </span>
            <span>Dashboard</span>
          </a>
          <a onClick={() => navigate("/prescriptionform")}>
            <span className="icon-1">
              <FaFilePrescription />
            </span>
            <span>Prescription</span>
          </a>
          <a onClick={() => navigate("/doctorpatientlist")}>
            <span className="icon-2">
              <i className="ri-user-2-line"></i>
            </span>
            <span>Patient</span>
          </a>
          <a onClick={() => navigate("/doctorappointmentlist")}>
            <span className="icon-3">
              <i className="ri-calendar-2-line"></i>
            </span>
            <span>Appointment</span>
          </a>
          <a onClick={() => navigate("")}>
            <span className="icon-4">
              <i className="ri-line-chart-line"></i>
            </span>
            <span>Payments</span>
          </a>
          <a href="#">
            <span className="icon-5">
              <i className="ri-settings-3-line"></i>
            </span>
            <span>Setting</span>
          </a>
          <a onClick={handleLogout}>
            <span className="icon-6">
              <i className="ri-logout-box-r-line"></i>
            </span>
            <span>Logout</span>
          </a>
        </nav>
      </div>

      <div className="main-content">
        <header>
          <button className="toggle-sidebar" onClick={toggleSidebar}>
            <i className={`fas ${isSidebarOpen ? "fa-times" : "fa-bars"}`}></i>
          </button>

          <section className="doctorsearchbox">
            <div className="search--notification--profile">
              <div className="search">
                <input type="text" placeholder="Search" />
                <button>
                  <i className="ri-search-2-line"></i>
                </button>
              </div>
          
              <div className="notification--profile">
           
                <div className="picon bell">
                  <i className="ri-notification-2-line"></i>
                </div>
                <div className="picon chat">
                  <i className="ri-wechat-2-line"></i>
                </div>
              </div>
            </div>
          </section>
        </header>
      </div>
    </>
  );
};

export default Drdashheader;


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
}
.search--notification--profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  
}

.notification--profile {
  display: flex;
  align-items: center;
}

.picon {
  margin-left: 20px;
  font-size: 1.1rem;
  padding: 5px;
  border-radius: 5px;
}

.lock {
  color: rgb(59, 213, 213);
  background-color: rgb(106, 168, 243);
}

.bell {
  color: #f1d243;
  background-color: #fff;
}

.chat {
  color: #70d7a5;
  background-color: #fff;
}

.search {
  background-color: #dfe5f1;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.icon-3 .ri-calendar-2-line{
  color: rgb(136, 127, 238);
  font-size: 20px;
}
.search input {
  background-color: transparent;
  outline: none;
  border: none;
  text-indent: 15px;
  width: 100%;
  cursor: pointer;
}

.search button {
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.search button i {
  font-size: 1.1rem;
  color: rgb(58, 173, 173);
}

.sidebar {
  position: fixed;
  width: 240px;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  z-index: 1000;
  left: -240px;
  border-right: 2px solid #f1f1f1;
  cursor: pointer;
}

.sidebar.open {
  left: 0;
}

.profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 14px;
}

.upload-button {
  position: absolute;
  top: 100px;
  right: 54px;
  background-color: rgb(188, 184, 184);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: rgb(58, 173, 173);
}

.sidebar nav a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #353535;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sidebar nav a:hover,
.sidebar nav a.active {
  background-color: #f0f0f0;
  color: rgb(58, 173, 173);
  border-left: 5px solid rgb(58, 173, 173);
}

.sidebar nav a span:first-child {
  margin-right: 15px;
  font-size: 20px;
}

.main-content {
  margin-left: 0;
  transition: margin-left 0.3s ease;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f7f4f4;
}

.toggle-sidebar {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #59c7c7;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: #dfe5f1;
  border-radius: 20px;
  padding: 5px 15px;
}

.search-container input {
  border: none;
  background: transparent;
  padding: 5px;
  outline: none;
}

.search-container button {
  background: none;
  border: none;
  cursor: pointer;
}

.notification-profile {
  display: flex;
  align-items: center;
}

.picon {
  margin-left: 15px;
  font-size: 20px;
  cursor: pointer;
}
 .icon-0 {
    color: rgb(58, 173, 173);
  }
  .icon-1 {
    color: rgb(109, 189, 247);
  }
  .icon-2 {
    color: #7792ff;
  }
  .icon-3 {
    color: rgb(136, 127, 238);
  }
  .icon-4 {
    color: #85ade3;
  }
  .icon-5 {
    color: #e36ac8;
  }
  .icon-6 {
    color: #70d7a5;
  }
  .icon-7 {
    color: #5f5ce0;
  }
  .icon-8 {
    color: #e86786;
  }
  .icon-9 {
    color: #2c8de1;
  }
  
.bell {
  color: #f1d243;
}

.chat {
  color: #70d7a5;
}

@media (min-width: 1025px) {
  .sidebar {
    left: 0;
  }

  .main-content {
    margin-left: 240px;
  }

  .toggle-sidebar {
    display: none;
  }
  .search--notification--profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  
    width: 200%;
  }
}

@media (max-width: 1024px) {
  .search-container {
    max-width: 200px;
  }
}

@media (max-width: 768px) {
  header {
    flex-wrap: wrap;
  }

  .search-container {
    order: 3;
    width: 100%;
    margin-top: 10px;
  }

  .notification-profile {
    margin-left: auto;
  }
}

*/