* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


/* Base styles */
.service-section {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.service-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.service-text {
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.serviceline {
  width: 1px;
  background-color: rgb(208, 205, 205);
  height: 610px;
  margin: 5px 0;
}

.covidawareness {
  color: rgb(92, 91, 91);
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 20px;
}

.wash {
  color: rgb(92, 91, 91);
  font-size: 16px;
  line-height: 1.5;
}

/* Desktop styles */
@media (min-width: 1025px) {
  .service-container {
    display: flex;
    justify-content: space-between;
  }

  .service-column {
    width: 48%;
  }

  .serviceline {
    display: block;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .service-icon {
    width: 30px;
    height: 30px;
  }

  .service-text {
    font-size: 18px;
  }

  .serviceline {
    display: none;
  }

  .covidawareness {
    font-size: 26px;
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  .service-icon {
    width: 25px;
    height: 25px;
  }

  .service-text {
    font-size: 16px;
  }

  .serviceline {
    display: none;
  }

  .covidawareness {
    font-size: 24px;
  }

  .wash {
    font-size: 14px;
  }
}
  
  /* Media queries for mobile phones */
  @media (max-width: 768px) {
    .covidlogo,
    .lablogo,
    .insurancelogo,
    .preventivelogo,
    .frequentlylogo, 
    .emergencylogo{
      display: none; /* Hide logos on mobile */
    }
  
    .serviceline {
      display: none; /* Hide service line on mobile */
    }
    .covid,
    .covid1,
    .emergency,
    .lab,
    .insurance,
    .preventive,
    .frequently {
      position: relative;
      left: auto;
      top: auto;
      padding: 0px; /* Add padding for better readability */
      text-align: left; /* Ensure text alignment */
    }
  
    .covid,
    .covid1 {
      position: relative;
      left: auto;
      top: auto;
      padding: 0px; /* Add padding for better readability */
      left: 10px;
    }
  
    .covidawareness {
      position: relative;
      left: auto;
      top: auto;
      padding: 0px; /* Add padding for better readability */
      left: 5px;
      font-size: 24px; /* Adjust font size for smaller screens */
    }
  
    .wash {
      position: relative;
      left: auto;
      top: auto;
      padding: 0px; /* Add padding for better readability */
      left: 5px;
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  }
  
   
   




















/*
import React from 'react'
import "../css/service.css"


const Service = () => {
  return (
    <>
    <section>
     <div className='covidlogo'><img src="https://www.maxhealthcare.in/img/covid-19-icon-for-menu.svg" alt="" /></div>
     <div className="covid">Covid-19 Vaccination</div>
     <div className="covid1">for children</div>

     <div ><img className='emergencylogo' src="https://www.maxhealthcare.in/img/emergency.png" alt="" /></div>
     <div className="emergency">Emergency</div>
     
     <div ><img className='lablogo' src="https://www.maxhealthcare.in/img/maxLab.svg" alt="" /></div>
     <div className="lab">Lab</div>
     
     <div ><img className='insurancelogo' src="https://www.maxhealthcare.in/img/InsuranceandTPA.svg" alt="" /></div>
     <div className="insurance">Insurance and TPA</div>
     

     <div ><img className='preventivelogo' src="https://www.maxhealthcare.in/img/PreventiveHealthChecks.svg" alt="" /></div>
     <div className="preventive">Preventive Health Checks</div>

     <div ><img className='frequentlylogo' src=" https://www.maxhealthcare.in/img/FrequentlyAskedQuestions.svg" alt="" /></div>
     <div className="frequently">Frequently Asked Question</div>
    
    </section>

    <div className="serviceline"></div>

    <section>

    <div className="covidawareness">Covid-19 Prevention</div>

    <div className="wash">Wash hands even if they are visibly clean. To cover your nose and mouth with handkerchief/tissue 
    while sneezing and coughing. To throw used tissues into closed bins immediately after use. To maintain a safe distance 
    from persons during interaction, especially with those having flu-like symptoms.
    </div>
    
  


    </section>


    </>
  )
}


export default Service;











.covidlogo{
 position: absolute;
 top: 114px;
 padding: 20px;
 left: 12%;

}
.covid{
position: absolute;
top: 106px;
padding: 20px;
left: 215px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;

}
.covid1{
position: absolute;
top: 128px;
padding: 20px;
left: 215px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;

}
  
.emergencylogo{
position: absolute;
top: 110px;
padding: 20px;
left: 32%;
height: 25px;
}
.emergency{
position: absolute;
top: 108px;
padding: 20px;
left: 530px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;

}
.lablogo{
position: absolute;
top: 180px;
padding: 20px;
left: 12%;
height: 25px;

}
.lab{
position: absolute;
top: 180px;
padding: 20px;
left: 215px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;

}
.insurancelogo{
position: absolute;
top: 180px;
padding: 20px;
left: 32%;
height: 25px;
}
.insurance{
position: absolute;
top: 180px;
padding: 20px;
left: 530px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;
   
}
.preventivelogo{
 position: absolute;
 top: 246px;
 padding: 20px;
 left: 12%;
 height: 25px;
    
}
.preventive{
position: absolute;
top: 246px;
padding: 20px;
left: 215px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;

}
.frequentlylogo{
position: absolute;
top: 246px;
padding: 20px;
left: 32%;
height: 25px;
    
}
.frequently{
position: absolute;
top: 246px;
padding: 20px;
left: 530px;
color: rgb(92, 91, 91);
font-size: 20px;
font-weight: 400;
cursor: pointer;
}


.serviceline{
width: 1px;
background-color: rgb(208, 205, 205);
height: 600px;
position: absolute;
top: 120px;
left: 900px;

}


.covidawareness{
    position: absolute;
    top: 106px;
    padding: 20px;
    left: 926px;
    color: rgb(92, 91, 91);
    font-size: 30px;
    font-weight: 400;
}
.wash{
    position: absolute;
    top: 150px;
    padding: 20px;
    left: 930px;
    color: rgb(92, 91, 91);
    
     
}







*/