@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.aboutus-section {
    position: relative;
    margin-bottom: 50px;
    
  }
  
  .aboutus-pic {
    width: 100%;
    left: 0;
    height: 400px;
    
   

  }
  
  .aboutus-content {
    position: absolute;
    top: 20%;
    left: 65%;
    color: rgb(114, 110, 110);
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .aboutheading {
    font-size: 37px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .aboutheading-paragraph p {
    margin: 5px 0;
    font-size: 18px;
  }
  
  .background-gray {
    background-color: rgb(255, 252, 252);
    padding: 50px 0;
    position: relative;
  }
  
  .about-content {
    width: 60%;
    margin: 0 auto;
    position: relative;
  }
  
  .about-icon, .about-icon2 {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
  }
  
  .about-icon {
    display: flex;
    flex-wrap: wrap;
    left: -240px;
    top: 0;
  }
  
  .about-icon2 {
    display: flex;
    flex-wrap: wrap;
    right: 60px;
    top: 0;
    transform: rotate(180deg);
  }
  
  .about-heading {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -140px;
    top: 0;
  }
  
  .doctoraboutpic-container {
    position: absolute;
    right: 7%;
    top: 13%;
    height: 80%;
  }
  
  .doctoraboutpic {
    height: 100%;
    width: auto;
  }
  
  .aboutus-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  
  .aboutus-card {
    background-color: #fff;
    width: 25%;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 2px 2px 8px #00000029;
    border-top: 5px solid rgb(7, 153, 153);
    margin-bottom: 20px;
  }
  
  .aboutsus-card-text {
    font-size: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(106, 101, 101);
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .aboutsus-card-text-para {
    text-align: center;
    font-size: 16px;
    color: rgb(85, 83, 83);
  }
  
  /* Tablet Styles */
  @media screen and (max-width: 1024px) {
    
  .about-icon {
    display: flex;
    flex-wrap: wrap;
    left: -94px;
    top: 0;
  }
  
  .about-icon2 {
    display: flex;
    flex-wrap: wrap;
    right: -70px;
    top: 0;
    transform: rotate(180deg);
  }
  
  .about-heading {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    left: -10px;
    top: 0;
  }
    .aboutus-content {
      left: 50%;
      width: 45%;
    }
  
    .about-content {
      width: 80%;
    }
  
    .doctoraboutpic-container {
      position: static;
      text-align: center;
      margin-top: 30px;
    }
  
    .doctoraboutpic {
      height: auto;
      width: 80%;
    }
  
    .aboutus-card {
      width: 40%;
    }
  }
  
  /* Mobile Styles */
  @media screen and (max-width: 768px) {
    .aboutus-content {
      position: static;
      width: 90%;
      margin: 20px auto;
      text-align: center;
    }
  
    .about-content {
      width: 90%;
    }
  
    .about-icon, .about-icon2 {
      display: none;
    }
  
    .doctoraboutpic {
      width: 50%;
    }
  
    .aboutus-card {
      width: 80%;
    }
    .aboutus-content {
        position: absolute;
        top: 20%;
        left: 5%;
        color: rgb(114, 110, 110);
        font-family: Arial, Helvetica, sans-serif;
      }
      
      .aboutheading {
        font-size: 27px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      
      .aboutheading-paragraph p {
        margin: 5px 0;
        left: 10%;
      }
      
  }