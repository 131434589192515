.patientregistrationbox {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.patientregistrationformbox {
  width: 100%;
}

.patientregistrationformbox h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
}

.patientregistrationformbox h3::after {
  content: "";
  width: 50px;
  height: 3px;
  background: #3498db;
  position: absolute;
  left: 0;
  bottom: 0px;
  border-radius: 5px;
}

.patient-input1 {
  margin-bottom: 1.5rem;
}

.patient-input1 input,
.patient-gender,
.prescription-symptoms,
.prescription-treatment {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;

}

.patient-input1 input:focus,
.patient-gender:focus,
.prescription-symptoms:focus,
.prescription-treatment:focus {
  outline: none;
  border-color: #3498db;
}

.prescription-symptoms,
.prescription-treatment {
  min-height: 90px;
  resize: none;
}

.patientregisterbtn {
  width: 100%;
  padding: 0.75rem;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 18px;

  outline: none;
  border: none;
  background-color: rgb(109, 189, 247);
  background-image: radial-gradient( 93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18% ), radial-gradient( 66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100% );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;

  
}

.patientregisterbtn:hover,
.patientregisterbtn:focus {
  background-color: rgb(73, 172, 230);
}

.success-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2ecc71;
  color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 1000;
  animation: fadeInOut 4s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.error {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

@media screen and (max-width: 768px) {
  .patientregistrationbox {
    width: 90%;
    padding: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .patientregistrationbox {
    width: 95%;
    padding: 1rem;
  }

  .patientregistrationformbox h3 {
    font-size: 1.25rem;
  }

  .patient-input1 input,
  .patient-gender,
  .prescription-symptoms,
  .prescription-treatment,
  .patientregisterbtn {
    font-size: 0.875rem;
  }
}





































/* 
yeh latest code hai


import "../css/prescriptionform.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';


const Patientregistration = () => {

const [name, nameSet] = useState('');
const [age, ageSet] = useState(''); 
const [mobile, mobileSet] = useState('');
const [gender, genderSet] = useState('');
const [symptoms, symptomsSet] = useState('');
const [treatment, treatmentSet] = useState('');
const [acceptTerms, setAcceptTerms] = useState(false);
const [errors, setErrors] = useState({});


const [doctor_id, getdoctor_id] = useState('');
const [doctor_mobile,getdoctor_mobile] = useState('');
const [doctor_name, getdoctor_name] = useState('');
const [doctor_email,getdoctor_email] = useState('');

const nameHandler =(event)=>{
  nameSet(event.target.value)
}
const ageHandler =(event)=>{
  ageSet(event.target.value)
}
const genderHandler =(event)=>{
  genderSet(event.target.value)
}
const mobileHandler =(event)=>{
  mobileSet(event.target.value)
}
const symptomsHandler =(event)=>{
  symptomsSet(event.target.value)
}
const treatmentHandler =(event)=>{
  treatmentSet(event.target.value)
}


const acceptTermsHandler = (event) => {
  setAcceptTerms(event.target.checked);
};


useEffect(()=>{
  getdoctor_id(localStorage.getItem('id'));
  getdoctor_mobile(localStorage.getItem('doctor_mobile'));
  getdoctor_name(localStorage.getItem('doctor_name'));
  getdoctor_email(localStorage.getItem('doctor_email'));

},[]);



const validateForm = () => {
  let tempErrors = {};
  if (!name.trim()) tempErrors.name = "Name is required";
  if (!age.trim()) tempErrors.age = "Age is required";
  else if (isNaN(age) || age < 0 || age > 120) tempErrors.age = "Please enter a valid age";
  if (!gender) tempErrors.gender = "Gender is required";
  if (!mobile.trim()) tempErrors.mobile = "Mobile number is required";
  else if (!/^\d{10}$/.test(mobile)) tempErrors.mobile = "Please enter a valid 10-digit mobile number";
  if (!symptoms.trim()) tempErrors.symptoms = "Symptoms are required";
  if (!treatment.trim()) tempErrors.treatment = "Treatment is required";
  if (!acceptTerms) tempErrors.acceptTerms = "You must accept the terms and conditions";
  setErrors(tempErrors);
  return Object.keys(tempErrors).length === 0;
};






const submitHandler =(event)=>{
  event.preventDefault();
    if (validateForm()) {
      let patientregistrationData = {
        name, age, gender, mobile, symptoms, treatment,password: '1234',
        doctor_id, doctor_mobile, doctor_name, doctor_email,
      };
      axios.post('http://localhost:8080/auth/patientregistration/', patientregistrationData)
        .then((response) => {
          console.log(response);
          // Clear form or show success message here
          nameSet('')
          ageSet('')
          genderSet('')
          mobileSet('')
          symptomsSet('')
          treatmentSet('')

             // Show success message
             Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Your Registration was submitted successfully!',
              confirmButtonText: 'OK',
              confirmButtonColor: '#0066ff'
            });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          // Show error message to user here
            // Show error message
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'There was an error submitting your message. Please try again.',
              confirmButtonText: 'OK',
              confirmButtonColor: '#0066ff'
            });
        });
    }
}

  return (
    <>
 <form name='patientregistrationform' onSubmit={submitHandler}>
      <div className="patientregistrationbox">
        <div className="patientregistrationformbox">
          <h3>Prescription Form</h3>
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Name"
              name='name'
              value={name}
              onChange={nameHandler}
              id='name'
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="number"
              placeholder="Age"
              name='age'
              value={age}
              onChange={ageHandler}
              id='age'
            />
            {errors.age && <div className="error">{errors.age}</div>}
          </div>
          <div className="patient-input1">
            <select
              className="patient-gender"
              name='Gender'
              value={gender}
              onChange={genderHandler}
              id='Speciality'
            >
              <option value="" className="gender-color">Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && <div className="error">{errors.gender}</div>}
          </div>
          <div className="patient-input1">
            <input
              type="text"
              placeholder="Mobile"
              name='mobile'
              value={mobile}
              onChange={mobileHandler}
              id='mobile'
            />
            {errors.mobile && <div className="error">{errors.mobile}</div>}
          </div>
          <div className="patient-input1">
            <textarea
              className="prescription-symptoms"
              placeholder="Symptoms"
              name='symptoms'
              value={symptoms}
              onChange={symptomsHandler}
              id='symptoms'
            />
            {errors.symptoms && <div className="error">{errors.symptoms}</div>}
          </div>
          <div className="patient-input1">
            <textarea
              className="prescription-treatment"
              placeholder="Treatment"
              name='treatment'
              value={treatment}
              onChange={treatmentHandler}
              id='treatment'
            />
            {errors.treatment && <div className="error">{errors.treatment}</div>}
          </div>
          <main className="policy1">
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={acceptTermsHandler}
            />
            <div>I accept all terms & condition</div>
          </main>
          {errors.acceptTerms && <div className="error">{errors.acceptTerms}</div>}
          <section>
            <button className="patientregisterbtn">Register Now</button>
          </section>
        </div>
      </div>
    </form>
     


     
    </>
  );
};

export default Patientregistration;












* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Desktop */
/* .patientregistrationbox {
  background-color: #fff;
  width: 30%;
  height: 76%;
  position: absolute;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  right: 5px;
  justify-content: space-around;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  top: 18%;
  left: 43%;
}


@media only screen and (max-width: 768px) {
  .patientregistrationbox {
    width: 50%;
    left: 25%;
  }
}

@media only screen and (max-width: 480px) {
  .patientregistrationbox {
    width: 90%;
    left: 5%;
    top: 10%;
  }
}

.patientregistrationformbox {
  width: 97%;
}

.patientregistrationformbox {
  padding: 1px 25px;
  display: flex;
  flex-direction: column;
}

.patientregistrationformbox h3 {
  font-size: 25px;
  font-weight: 500;
  color: rgb(60, 58, 58);
  position: absolute;
  bottom: 88%;
}

.patientregistrationformbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(109, 189, 247);
  position: absolute;
  left: 0px;
  bottom: 2px;
  border-radius: 5px;
}

.patient-input1 {
  width: 88%;
  margin-bottom: 10px;
  position: relative;
  bottom: 3%;
}

.patient-input1 input {
  display: block;
  width: 100%;
  padding: 10px 5px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 16px;
  font-weight: 515;
  color: #6b7280;
}
.patient-gender{
  display: block;
  width: 102%;
  padding: 10px 0px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 16px;
  font-weight: 530;
  color: #6b7280;
  
}
.prescription-symptoms{
  width: 100%;
  min-height: 70px;
  resize: none;
  padding: 5px 5px;
  border: none;
  outline: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 16px;
  color: #6b7280;
}

.prescription-treatment{
  width: 100%;
  min-height: 70px;
  resize: none;
  padding: 5px 5px;
  border: none;
  outline: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 16px;
  color: #6b7280;
}

.patientregisterbtn {
  position: relative;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  top: 50%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 92%;
  background-color: rgb(109, 189, 247);
  background-image: radial-gradient( 93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18% ), radial-gradient( 66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100% );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

.patientregisterbtn:hover, .patientregisterbtn:focus {
  background-color: rgb(73, 172, 230);
}

.policy1{
  color: #333;
  top: 78%;
  display: flex;
  position: absolute;
  cursor: pointer;
} */ 





























































  /*



  
import "../css/prescriptionform.css";
import patientregistrationimg from "./image/patientregistrationimg.png";
import { useEffect, useState } from "react";
import axios from "axios";


const Patientregistration = () => {

const [name, nameSet] = useState('');
const [age, ageSet] = useState(''); 
const [mobile, mobileSet] = useState('');
const [gender, genderSet] = useState('');
const [symptoms, symptomsSet] = useState('');
const [treatment, treatmentSet] = useState('');


const [doctor_id, getdoctor_id] = useState('');
const [doctor_mobile,getdoctor_mobile] = useState('');
const [doctor_name, getdoctor_name] = useState('');
const [doctor_email,getdoctor_email] = useState('');

const nameHandler =(event)=>{
  nameSet(event.target.value)
}
const ageHandler =(event)=>{
  ageSet(event.target.value)
}
const genderHandler =(event)=>{
  genderSet(event.target.value)
}
const mobileHandler =(event)=>{
  mobileSet(event.target.value)
}
const symptomsHandler =(event)=>{
  symptomsSet(event.target.value)
}
const treatmentHandler =(event)=>{
  treatmentSet(event.target.value)
}




useEffect(()=>{
  getdoctor_id(localStorage.getItem('id'));
  getdoctor_mobile(localStorage.getItem('doctor_mobile'));
  getdoctor_name(localStorage.getItem('doctor_name'));
  getdoctor_email(localStorage.getItem('doctor_email'));

},[]);


const submitHandler =(event)=>{
  event.preventDefault();
  let patientregistrationData = {name:name,age:age,gender:gender,mobile:mobile,symptoms:symptoms,treatment:treatment ,doctor_id:doctor_id,
  doctor_mobile:doctor_mobile,doctor_name:doctor_name,doctor_email:doctor_email};
  return axios.post('http://localhost:8080/auth/patientregistration/',patientregistrationData).then((response)=>{
  console.log(response);

 })
}

  return (
    <>

<form  name='patientregistrationform' onSubmit={submitHandler}>
   
    <div className="patientregistrationbox">
          <div className="patientregistrationformbox">
            <h3>Prescription Form</h3>
            <div className="patient-input1">

              <input type="text" placeholder="Name"  name='name' onChange={nameHandler}
              id='name' data-required="true" data-error-message="Your Name Is Required"/>
            </div>
            <div className="patient-input1">
        
              <input type="text" placeholder="Age"  name='age' onChange={ageHandler}
               id='email' data-required="true" data-error-message="Your Age Is Required"/>
            </div>
            <div className="patient-input1">
        
              <input type="text" placeholder="Gender"  name='Gender' onChange={genderHandler}
                id='Speciality' data-required="true" data-error-message="Your Gender Is Required" />
            </div>
            <div className="patient-input1">
        
             <input type="text" placeholder="Mobile" name='mobile' onChange={mobileHandler}
               id='mobile' data-required="true" data-error-message="Your Mobile Is Required"/>
            </div>
            <div className="patient-input1">

              

              <textarea className="prescription-symptoms" type="text" placeholder="Symptoms"  name='symptoms' onChange={symptomsHandler}
                  id='password' data-required="true" data-error-message="Your Password Is Required" />
            </div>
            <div className="patient-input1">
        
            <textarea className="prescription-treatment" type="text" placeholder="Treatment"  name='treatment' onChange={treatmentHandler}
                id='treatment' data-required="true" data-error-message="Your Treatment Is Required" />
            </div>
          
            <main className="policy1">
        <input type="checkbox"/>
        <div>I accept all terms & condition</div>
            </main>

            <section>
              <button className="patientregisterbtn">Register Now</button>
            </section>
          
          </div>
        </div>

  

  </form>
     


     
    </>
  );
};

export default Patientregistration;








.patientregistrationbox {
    background-color: white;
    width: 30%;
    height: 75%;
    position: absolute;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    right: 5px;
    justify-content: space-around;
    box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
    top: 18%;
    left: 43%;
  }

  


  
  .patientregistrationformbox {
    width: 97%;
  }
  
  .patientregistrationformbox {
    padding: 1px 25px;
    display: flex;
    flex-direction: column;
  }
  .patientregistrationformbox h3 {
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    bottom: 88%;
  
  }
  .patientregistrationformbox h3::after {
    content: "";
    width: 30px;
    height: 3px;
    background: rgb(109, 189, 247);
    position: absolute;
    left: 0px;
    bottom: 2px;
    border-radius: 5px;
  }
  
  
  .patient-input1 {
    width: 88%;
    margin-bottom: 10px;
    position: relative;
    bottom: 3%;
  }
  .patient-input1 input {
    display: block;
    width: 100%;
    padding: 10px 5px;
    outline: none;
    border: none;
    border-bottom: 2px solid rgb(182, 180, 180);
    font-size: 16px;
    font-weight: 515;
  }
  .prescription-symptoms{
    width: 100%;
    min-height: 70px;
    resize: none;
    padding: 5px 5px;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(182, 180, 180);
    font-size: 16px;
  }
  .prescription-treatment{
    width: 100%;
    min-height: 70px;
    resize: none;
    padding: 5px 5px;
    border: none;
    outline: none;
    border-bottom: 2px solid rgb(182, 180, 180);
    font-size: 16px;
  }
  .patientregisterbtn {
    position: relative;
    height: 45px;
    border-radius: 10px;
    font-size: 18px;
    top: 50%;
    color: #fff;
    outline: none;
    border: none;
    padding: 8px 16px;
    width: 94%;
    background-color: rgb(109, 189, 247);
    background-image: radial-gradient(
        93% 87% at 87% 89%,
        rgba(0, 0, 0, 0.23) 0%,
        transparent 86.18%
      ),
      radial-gradient(
        66% 66% at 26% 20%,
        rgba(255, 255, 255, 0.55) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      );
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
      inset 0px 3px 9px rgba(255, 255, 255, 0.3),
      inset 0px 1px 1px rgba(255, 255, 255, 0.6),
      inset 0px -8px 36px rgba(0, 0, 0, 0.3),
      inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    cursor: pointer;
  }
  .patientregisterbtn:hover,
  .patientregisterbtn:focus {
    background-color: rgb(73, 172, 230);
  }
  .patientalready{
    color: #333;
    top: 91%;
    display: flex;
    position: absolute;
    cursor: pointer;
  
   
   }
   .patientalready1{
    text-decoration: none;
    color: black;
   }
   .policy1{
    display: flex;
    align-items: center;
    top: 78%;
    position: absolute;
  
  }





*/