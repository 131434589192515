@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.error-message {
  background-color: #ffebee;
  border: 1px solid #ef5350;
  color: #b71c1c;
  padding: 3px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.error-message i {
  margin-right: 10px;
  color: #ef5350;
}

.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 87vh;
  padding: 10px;
}

.loginbox {
  background-color: white;
  width: 100%;
  max-width: 790px;
  position: relative;
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  overflow: hidden;
}

.formbox {
  width: 50%;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.loginimg {
  width: 80%;
  overflow: hidden;
}

.loginimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.formbox h3 {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
}

.formbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(10, 153, 153);
  position: absolute;
  left: 0;
  bottom: 0px;
  border-radius: 20px;
}

.input-field {
  position: relative;
  margin-bottom: 20px;
}

.input-field input {
  width: 100%;
  padding: 0px 30px 7px;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 15px;
  outline: none;
}

.fa {
  color: rgb(10, 153, 153);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  font-size: 18px;
}

.fa-eye, .fa-eye-slash {
  right: 5px;
  left: auto;
  cursor: pointer;
}

.drforgot, .drsignup {
  margin-bottom: 10px;
  cursor: pointer;

}

.loginbtn, .patient {

  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: rgb(53, 188, 188);
  background-color: #38d2d2;
  background-image: radial-gradient(
    93% 87% at 87% 89%,
    rgba(0, 0, 0, 0.23) 0%,
    transparent 86.18%
  ),
  radial-gradient(
    66% 66% at 26% 20%,
    rgba(255, 255, 255, 0.55) 0%,
    rgba(255, 255, 255, 0) 69.79%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  height: 45px;
  transition: background-color 0.3s;


}

.loginbtn:hover, .patient:hover {
  background-color: rgb(26, 176, 176);
}

.or {
  text-align: center;
  font-weight: bold;
   margin-left: -1%;
   bottom: 10%;
}

.error {
  color: rgb(202, 47, 47);
  font-size: 14px;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .loginimg {
    display: none;
  }
  
  .formbox {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .loginbox {
    width: 90%;
  }
  
  .formbox {
    padding: 30px;
  }
  
  .formbox h3 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .loginbox {
    width: 100%;
  }
  
  .formbox {
    padding: 20px;
  }
  
  .formbox h3 {
    font-size: 20px;
  }
  
  .input-field input {
    font-size: 14px;
  }
}



























































/*

import { useState } from "react";
import "../css/dashboard.css";
import dashimg from "./image/dashimg.png";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2';

const Dashboard = () => {
  const navigate = useNavigate();
  
  const [doctor_mobile, doctor_mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const doctormobileHandler = (event) => {
    doctor_mobileSet(event.target.value);
  }
  
  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }


  const validateForm = () => {
    let formErrors = {};

    if (!doctor_mobile) {
      formErrors.doctor_mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(doctor_mobile)) {
      formErrors.doctor_mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!password) {
      formErrors.password = "Password is required";
    } else if (password.length < 4) {
      formErrors.password = "Password must be at least 4 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      let loginData = { doctor_mobile: doctor_mobile, password: password };
      axios.post('http://localhost:8080/doctor/doctorlogin/', loginData)
        .then((response) => {
          console.log(response.data.message.doctordata[0]);
          
          doctor_mobileSet('');
          passwordSet('');

          if (response.data) {
            localStorage.setItem('id', response.data.message.doctordata[0].id);
            localStorage.setItem('doctor_mobile', response.data.message.doctordata[0].doctor_mobile);
            localStorage.setItem('doctor_name', response.data.message.doctordata[0].doctor_name);
            localStorage.setItem('doctor_email', response.data.message.doctordata[0].doctor_email);
            navigate('/drdashboard');
          } else {
            navigate('/dashboard');
          }


        })
        .catch((error) => {
          console.error("Login error:", error);
        
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Login failed. Please check your credentials and try again.",
            
          });
        });
    }
  }

  return (
    <>
      <form name="loginform" onSubmit={submitHandler}>
        <div className="loginbox">
          <div className="formbox">
            <h3>Login to Doctor</h3>
            <div className="input-field">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="doctor_mobile" 
                onChange={doctormobileHandler}
                id="doctor_mobile" 
                value={doctor_mobile}
              />
              {errors.doctor_mobile && <div className="error">{errors.doctor_mobile}</div>}
            </div>
                     <div className="input-field">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input 
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password" 
                name="password" 
                onChange={passwordHandler}
                id="password" 
                value={password}
              />
              <i 
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`} 
                aria-hidden="true"
                onClick={togglePasswordVisibility}
                style={{cursor: 'pointer', position: 'absolute', left: '108%', top: '50%', transform: 'translateY(-50%)'}}
              ></i>
              {errors.password && <div className="error">{errors.password}</div>}
            </div>
            <a className="drforgot" href="#">Forgot password?</a>
            <div className="drsignup">
              Don't have an account? <a onClick={() => navigate('/drregistration')}>Signup now</a>
            </div>
            <section>
              <button className="loginbtn" type="submit">Login</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="patient" type="button" onClick={() => navigate('/patient')}>As a Patient</button>
            </section>
          </div>
        </div>
        <figure className="loginimg">
          <img src={dashimg} alt="" />
        </figure>
      </form>
    </>
  );
};

export default Dashboard;







.loginbox {
  background-color: white;
  width: 50em;
  height: 27em;
  position: absolute;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  right: 5px;
  justify-content: space-around;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  top: 25%;
  left: 24%;
}
.loginimg {
  width: 11%;
  height: 433px;
  position: absolute;
  left: 656px;
  top: 168px;
}

.loginimg img {
  height: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 15px;
  width: 280%;
}

.formbox {
  width: 34%;
}

.formbox {
  padding: 1px 25px;
  display: flex;
  flex-direction: column;
}
.formbox h3 {
  font-size: 28px;
  font-weight: 500;
  position: absolute;
  top: 0;
}
.formbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(10, 153, 153);
  position: absolute;
  left: 0px;
  bottom: 2px;
  border-radius: 20px;
}

.fa {
  color: rgb(10, 153, 153);
  position: absolute;
  top: 10px;
  font-size: 18px;
}

.input-field {
  width: 84%;
  margin-bottom: 10px;
  position: relative;
  bottom: 12%;
}
.input-field input {
  display: block;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 15px;
}

.drforgot {
  color: black;
  text-decoration: none;

  bottom: 20px;
  position: relative;
}
.drsignup {
  text-decoration: none;
  bottom: 10px;
  position: relative;
  cursor: pointer;
}

.loginbtn {
  position: relative;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 6%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 100%;
  background-color: rgb(53, 188, 188);
  background-color: #38d2d2;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.55) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}
.loginbtn:hover,
.loginbtn:focus {
  background-color: rgb(26, 176, 176);
}

.loginbtn + p {
  text-align: center;

  font-size: 14px;
}

.loginbtn + p a {
  text-decoration: none;
  color: #7227d5;
  font-weight: 500;
}

input::placeholder {
  font-family: "Poppins", sans-serif;
}

.or {
  position: absolute;
  top: 74%;
  left: 19%;
  font-weight: bold;
}
.patient {
  position: absolute;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 4%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 34%;
  background-color: rgb(53, 188, 188);
  background-color: #38d2d2;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.55) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

.patient:hover,
.patient:focus {
  background-color: rgb(26, 176, 176);
}





*/