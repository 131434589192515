
.settingcontainer {
  background-color: #fff;
  width: 100%;
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.account-setting {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
}



.setting-form-group {
  margin-bottom: 1.5rem;
}

.account-info {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.doctor-setting-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.doctor-setting-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: none;
}

/* From Uiverse.io by satyamchaudharydev */ 
.doctor-setting-button{
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color:  rgb(35, 165, 165);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 100px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 20px;
  cursor: pointer;
  margin-top: 5%;
}

.doctor-setting-button-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.doctor-setting-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.doctor-setting-button:hover .doctor-setting-button-icon {
  transform: translate(4px);
}

.doctor-setting-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.doctor-setting-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}


@media screen and (max-width: 768px) {
  .settingcontainer {
    width: 90%;
    padding: 1.5rem;
    margin: 1.5rem auto;
  }
  
  .account-setting {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 480px) {
  .settingcontainer {
    width: 95%;
    padding: 1rem;
    margin: 1rem auto;
  }

  .account-setting {
    font-size: 1.125rem;
  }

  .account-info {
    font-size: 0.875rem;
  }

  .doctor-setting-input,
  .doctor-setting-button {
    font-size: 0.875rem;
    padding: 0.625rem;
  }
}