@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* :root {
  

    --patientslidebar-color: #fff;
    --primary-color: rgb(52, 163, 163);
    --primary-color-light: #f6f5ff;
    --toggle-color: #ddd;
    --text-color: #707070;
  
  
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
  }
  
 
  
  ::selection {
    background-color: var(--primary-color);
    color: #fff;
  }
  

  
  .patientslidebar {
    position: fixed;
    top: 0;
    left: 0%;
    height: 100%;
    width: 250px;
    padding: 10px 14px;
    background: var(--patientslidebar-color);
    border-right: 2px solid #f1f1f1;
    transition: var(--tran-05);
    z-index: 100;
  }
  .patientslidebar.close {
    width: 88px;
  }
  
  .patientslidebar li {
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .patientslidebar  .image,
  .patientslidebar .icon {
    min-width: 60px;
    border-radius: 6px;
  }
  
  .patientslidebar .icon {
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  
  .patientslidebar .text,
  .patientslidebar .icon {
    color: var(--text-color);
    transition: var(--tran-03);
  }
  
  .patientslidebar .text {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
  }
  .patientslidebar.close .text {
    opacity: 0;
  }

  
  .patientslidebar  {
    position: relative;
  }
  
  .patientslidebar  .image-text {
    display: flex;
    align-items: center;
  }
  .patientslidebar  .logo-text {
    display: flex;
    flex-direction: column;
  } */
 .patient-name {
  margin-top: 2px;
  font-size: 28px;
  font-weight: 600;
  position: relative;
  right: 10%;
  font-style: initial;
  color: rgb(18, 96, 96);
}

.patient-profession {
  font-size: 22px;
  font-weight: 400;
  margin-top: 5px;
  display: block;
  position: relative;
  right: 10%;
  color: rgb(18, 96, 96);
}

.patient-text-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 40px;
}


  .patient-search--notification--profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .patient-notification--profile {
    display: flex;
    align-items: center;
  }
  
  .patient-picon {
    margin-left: 20px;
    font-size: 1.1rem;
    padding: 5px;
    border-radius: 5px;
  }
  
  .patient-lock {
    color: rgb(59, 213, 213);
    background-color: rgb(106, 168, 243);
  }
  
  .patient-bell {
    color: #f1d243;
    background-color: #fff;
  }
  
  .patient-chat {
    color: #70d7a5;
    background-color: #fff;
  }
  
  .patient-search {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 500px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .patient-icon-3 .ri-calendar-2-line {
    color: rgb(136, 127, 238);
    font-size: 20px;
  }
  
  .patient-search input {
    background-color: transparent;
    outline: none;
    border: none;
    text-indent: 15px;
    width: 100%;
    cursor: pointer;
  }
  
  .patient-search button {
    outline: none;
    border: none;
    border-radius: 50%;
    background-color: #fff;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .patient-search button i {
    font-size: 1.1rem;
    color: rgb(58, 173, 173);
  }
  
  .patient-sidebar {
    position: fixed;
    width: 240px;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease;
    z-index: 1000;
    left: -240px;
    border-right: 2px solid #f1f1f1;
    cursor: pointer;
  }
  
  .patient-sidebar.open {
    left: 0;
  }
  
  .patient-profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  
  .patient-profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .patient-profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .patient-default-picture {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    font-size: 14px;
  }
  
  .patient-upload-button {
    position: absolute;
    top: 100px;
    right: 54px;
    background-color: rgb(188, 184, 184);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .patient-upload-button:hover {
    background-color: rgb(58, 173, 173);
  }
  
  .patient-sidebar nav a {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    color: #353535;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .patient-sidebar nav a:hover,
  .patient-sidebar nav a.active {
    background-color: #f0f0f0;
    color: rgb(58, 173, 173);
   
  }
  
  .patient-sidebar nav a span:first-child {
    margin-right: 15px;
    font-size: 20px;
  }
  
  .patient-main-content {
    margin-left: 0;
    transition: margin-left 0.3s ease;
  }
  
  .patient-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f7f4f4;
  }
  
  .patient-toggle-sidebar {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #59c7c7;
  }
  
  .patient-search-container {
    display: flex;
    align-items: center;
    background-color: #dfe5f1;
    border-radius: 20px;
    padding: 5px 15px;
  }
  
  .patient-search-container input {
    border: none;
    background: transparent;
    padding: 5px;
    outline: none;
  }
  
  .patient-search-container button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .patient-notification-profile {
    display: flex;
    align-items: center;
  }
  
  .patient-picon {
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .patient-icon-0 {
    color: rgb(58, 173, 173);
  }
  .patient-icon-1 {
    color: rgb(109, 189, 247);
  }
  .patient-icon-2 {
    color: #7792ff;
  }
  .patient-icon-3 {
    color: rgb(136, 127, 238);
  }
  .patient-icon-4 {
    color: #85ade3;
  }
  .patient-icon-5 {
    color: #e36ac8;
  }
  .patient-icon-6 {
    color: #70d7a5;
  }
  .patient-icon-7 {
    color: #5f5ce0;
  }
  .patient-icon-8 {
    color: #e86786;
  }
  .patient-icon-9 {
    color: #2c8de1;
  }
  
  .patient-bell {
    color: #43c8f1;
  }
  
  .patient-chat {
    color: #70d7a5;
  }
  
  @media (min-width: 1025px) {
    .patient-sidebar {
      left: 0;
    }
  
    .patient-main-content {
      margin-left: 240px;
    }
  
    .patient-toggle-sidebar {
      display: none;
    }
    .patient-search--notification--profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 200%;
    }
  }
  
  @media (max-width: 1024px) {
    .patient-search-container {
      max-width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .patient-header {
      flex-wrap: wrap;
    }
  
    .patient-search-container {
      order: 3;
      width: 100%;
      margin-top: 10px;
    }
  
    .patient-notification-profile {
      margin-left: auto;
    }
  }


  
/*   
  .patientslidebar  .image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .patientslidebar  .image img {
    width: 40px;
    border-radius: 6px;
  }
  
  .patientslidebar  .toggle {
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    color: var(--patientslidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
  }
  
  body.dark .patientslidebar  .toggle {
    color: var(--text-color);
  }
  
  .patientslidebar.close .toggle {
    transform: translateY(-50%) rotate(0deg);
  }
  
  .patientslidebar .menu {
    margin-top: 40px;
  }
  
  .patientslidebar li.search-box {
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
  }
  
  .patientslidebar li.search-box input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
  }
  .patientslidebar li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
  }
  
  .patientslidebar li a:hover {
    background-color: var(--primary-color);
  }
  .patientslidebar li a:hover .icon,
  .patientslidebar li a:hover .text {
    color: var(--patientslidebar-color);
  }
  body.dark .patientslidebar li a:hover .icon,
  body.dark .patientslidebar li a:hover .text {
    color: var(--text-color);
  }
  
  .patientslidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
  }
  .menu-bar::-webkit-scrollbar {
    display: none;
  }
 
 

 

  
 
 */
