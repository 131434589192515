@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
a {
    text-decoration: none;
}

li {
    list-style: none;
    
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: white;
    box-shadow: 0 -1px 26px #00000029;
    
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.nav-links li {
    margin-right: 15px;
}

.nav-links li a {
    color: black;
    text-decoration: none;
    font-size: 1.1rem;
    cursor: pointer;
    
}

.nav-links li a:hover {
    text-decoration: underline;
    color: rgb(61, 167, 159);
 
}

.logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.name {
    font-size: 30px;
    font-style: initial;
    color: rgb(18, 96, 96);
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #b0adad;
}

@media (max-width: 1024px) {
    .hamburger {
        display: block;
    }
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-links {
        position: fixed;
        position: absolute;
        left: -100%;
        top: 61px;
        gap: 0;
        flex-direction: column;
        background-color: rgb(110, 193, 193);
        width: 100%;
        text-align: center;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
        z-index: 10;
    }
    .nav-links li a {
        color: #fff;
        text-decoration: none;
        font-size: 1.1rem;
        cursor: pointer;
    }
    .name {
        font-size: 18px;
        font-style: initial;
        color: rgb(18, 96, 96);
    }
    .logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
    .nav-links li a:hover {
        text-decoration: underline;
        color: rgb(61, 167, 159);
    }

    .nav-links.active {
        left: 0;
    }

    .nav-links li {
        margin: 16px 0;
    }
}



/*


import React from 'react'
import "../css/header.css"
import logomed from './image/logomed.png'
import { useNavigate } from 'react-router-dom'


const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <nav class="navbar">
   
   <div> <img class="logo" src={logomed} alt="" /></div>
   <div className='name'><b>Medical</b> Health care</div>
   
     <div class="nav-links">

     <li><a onClick={() => navigate('/')}>Home</a></li>
     <li><a onClick={() => navigate('/treatments')}>Treatments</a></li>
     <li><a onClick={() => navigate('/doctors')}>Doctors</a></li>
     <li><a onClick={() => navigate('/service')}>Service</a></li>
     <li><a onClick={() => navigate('/aboutus')}>About Us</a></li>
     <li><a onClick={() => navigate('/contactus')}>Contact Us</a></li>  
     <li><a onClick={() => navigate('/dashboard')}>Dashboard</a></li>
    
    
     </div>
  
 </nav>
   
      
    </>
  )
}

export default Header;




a{
    text-decoration: none;
}
li{
    list-style: none;
}
.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: white;
    cursor: pointer;
    box-shadow: 0 -1px 26px #00000029;
  

}

.nav-links {
    list-style: none;
    display: flex;
}

.nav-links li {
    margin-right: 25px;
   
}

.nav-links li a {
    color: black;
    text-decoration: none;
    font-size: 1.1rem;
}

.nav-links li a:hover {
    text-decoration: underline;
    color: rgb(61, 167, 159);
    
}
.logo{
    width: 50px;
    height: 50px;
    margin-left: 20%;
}
.name{
    font-size: 30px;
    margin-right: 32%;
    font-style: initial;
    color: rgb(18, 96, 96);
   
    
}
    */