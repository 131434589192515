.hospital-footer {
    background-color: white;
    color: #333;
    font-family: Arial, sans-serif;
    box-shadow: 0 -1px 26px #00000029;
    margin-top: 0;
    padding: 35px 0;
}

.container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-logo {
    color: rgb(5, 102, 102);
    font-size: 22px;
    display: flex;
    align-items: center;
}

.footer-logo img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.footer-info {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    max-width: 60%;
}

.contact-info h3,
.social-links h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-info p {
    margin: 5px 0;
}

.social-links ul {
    list-style: none;
    padding: 0;
    display: flex;
}

.social-links ul li {
    margin-right: 14px;
}

.social-links ul li:last-child {
    margin-right: 0;
}

.social-links ul li a img {
    width: 55px;
    height: 55px;
    border-radius: 20%;
    box-shadow: 7px 11px 12px #dedddd;
    transition: all 0.5s ease-in-out;
    transform: translateY(0);
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-logo {
        margin-bottom: 20px;
    }

    .footer-info {
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }

    .contact-info, .social-links {
        text-align: center;
        margin-bottom: 20px;
    }

    .social-links ul {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .container {
        width: 90%;
    }

    .footer-logo {
        font-size: 18px;
    }

    .footer-logo img {
        width: 40px;
        height: 40px;
    }

    .social-links ul li a img {
        width: 45px;
        height: 45px;
    }
}














/* 
import React from "react";
import "../css/footer.css";
import logomed from "./image/logomed.png";

const Footer = () => {
  return (
    <>
      <footer class="hospital-footer">
        <div class="container">
          <div class="footer-content">
            <div class="footer-logo">
              <img src={logomed} alt="" />
              <div>
                <b>Medical</b> Health care
              </div>
            </div>
            <div class="contact-info">
              <h3>Contact Us</h3>
              <p>Delhi</p>
              <p>& Noida</p>
              <p>Email: info@medicalhealthcare.com</p>
            </div>
            <div class="social-links">
              <h3>Follow Us</h3>
              <ul>
                <li>
                  <a href="#">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/instagrame.png"
                      alt="instagram"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/facebook.png"
                      alt="facebook"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="https://fhlazwebsau01.blob.core.windows.net/drupal-data/2023-03/twitter.png"
                      alt="twitter"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;





.hospital-footer {
    background-color: white;
    padding: 20px 0;
    color: #333;
    font-family: Arial, sans-serif;
    box-shadow: 0 -1px 26px #00000029;
    margin-top: 0px;
    padding: 35px 0;
}

.container {
    width: 80%;
    margin: 0 auto;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footer-logo{
    color: rgb(5, 102, 102);
    font-size: 22px;
    display: flex;
    
}
.footer-logo img {
    width: 50px;
    height: 50px;
    bottom: 20px;
    position: relative;
}

.contact-info h3,
.social-links h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.contact-info p {
    margin: 5px 0;
}

.social-links ul {
    list-style: none;
    padding: 0;
}

.social-links ul li {
    display: inline-block;
    margin-right: 10px;
}

.social-links ul li:last-child {
    margin-right: 0;
}

.social-links ul li a img {

    width: 55px;
    height: 55px;
    display: flex;
    border-radius: 20%;
    box-shadow: 7px 11px 12px #dedddd;
    margin-right: 14px;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in all; 
    transform: translateY(0);
} */

