@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.registration-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
 
}

.registration-card {
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  padding: 2rem;
}

.registration-title {
  font-family: 'Poppins', sans-serif;
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 2rem;
  position: relative;
}

.registration-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.5rem;
  width: 3rem;
  height: 0.25rem;
  background: #64b5f6;
  border-radius: 0.25rem;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.input-group {
  position: relative;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.input-group input:focus,
.input-group select:focus {
  border-color: #64b5f6;
  box-shadow: 0 0 0 2px rgba(100, 181, 246, 0.2);
  outline: none;
}

.policy-group {
  margin: 1.5rem 0;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.checkbox-label input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
}


.register-button {
  width: 100%;
  padding: 0.875rem;
  border: none;
  border-radius: 0.5rem;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
 transition: transform 0.2s, box-shadow 0.2s; 


outline: none;

background-color: rgb(109, 189, 247);
background-image: radial-gradient( 93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18% ), radial-gradient( 66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100% );
box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
}

.register-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(100, 181, 246, 0.2);
}

.login-link {
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
}

.login-link span {
  color: #64b5f6;
  cursor: pointer;
  font-weight: 500;
}

.login-link span:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .registration-card {
    padding: 1.5rem;
  }

  .registration-title {
    font-size: 1.5rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .registration-container {
    padding: 1rem;
  }

  .registration-card {
    padding: 1rem;
  }

  .registration-title {
    font-size: 1.25rem;
  }
}





















/* 


import { useNavigate } from "react-router-dom";
import "../css/drregistration.css";
import drregistrationimg from "./image/drregistrationimg.png";
import { useState } from "react";
import axios from 'axios';


const Drregistration = () => {
  const navigate = useNavigate();

  const [doctor_name, doctornameSet] = useState('');
  const [doctor_email, doctoremailSet] = useState('');
  const [doctor_mobile, doctormobileSet] = useState('');
  const [position, positionSet] = useState('');
  const [speciality, specialitySet] = useState('');
  const [experience, experienceSet] = useState('');
  const [gender, genderSet] = useState('');
  const [password, passwordSet] = useState('');

  const doctornameHandler =(event)=>{
    doctornameSet(event.target.value)
  }
  const doctoremailHandler =(event)=>{
    doctoremailSet(event.target.value)
  } 
  const doctormobileHandler =(event)=>{
    doctormobileSet(event.target.value)
  } 
  const positionHandler =(event)=>{
    positionSet(event.target.value)
  }
  const specialityHandler =(event)=>{
    specialitySet(event.target.value)
  } 
  const experienceHandler =(event)=>{
    experienceSet(event.target.value)
  }
  const genderHandler =(event)=>{
    genderSet(event.target.value)
  }
  const passwordHandler =(event)=>{
    passwordSet(event.target.value)
  }

  const submitHandler =()=>{
 
   
    let drregistrationData ={doctor_name,doctor_email,doctor_mobile,position,speciality,experience,gender,password};
    return axios.post('http://localhost:8080/doctor/doctorregistration/',drregistrationData).then((response)=>{
      console.log(response);
     
    
    })
    
  }

  return (
    <>

<form   name='drregistrationform' onSubmit={submitHandler}>
   
    <div className="drregistrationbox">
          <div class="registrationformbox">
            <h3>Registration to Doctor</h3>
            <div class="input-field1">

              <input type="text" placeholder="Doctor name"  name='Doctor name' onChange={doctornameHandler}
              id='name' data-required="true" data-error-message="Your Name Is Required"/>
            </div>
            <div class="input-field1">
        
              <input type="text" placeholder="Doctor email"  name='Doctor email' onChange={doctoremailHandler}
              id='Doctor email' data-required="true" data-error-message="Your Email Is Required"/>
            </div>
            <div class="input-field1">
        
              <input type="text" placeholder="Doctor mobile" name='Doctor mobile' onChange={doctormobileHandler}
              id='Doctor mobile' data-required="true" data-error-message="Your Mobile Is Required"/>
            </div>
            <div class="input-field1">
            
            <input type="text" placeholder="Position"  name='Position' onChange={positionHandler}
            id='Position' data-required="true" data-error-message="Your Position Is Required" />
          </div>
            
            <div class="input-field1">
        
              <input type="text" placeholder="Speciality"  name='Speciality' onChange={specialityHandler}
              id='Speciality' data-required="true" data-error-message="Your Speciality Is Required" />
            </div>

            <div class="input-field1">
        
        <input type="text" placeholder="Experience"  name='Experience' onChange={experienceHandler}
        id='Experience' data-required="true" data-error-message="Your Experience Is Required" />
      </div>

      <div class="input-field1">
        
        <input type="text" placeholder="Gender"  name='Gender' onChange={genderHandler}
        id='Gender' data-required="true" data-error-message="Your Gender Is Required" />
      </div>
            <div class="input-field1">
        
              <input type="password" placeholder="Password"  name='password' onChange={passwordHandler}
              id='password' data-required="true" data-error-message="Your Password Is Required" />
            </div>
          
            <main class="policy">
        <input type="checkbox"/>
        <div>I accept all terms & condition</div>
            </main>

            <section>
              <button className="drregisterbtn">Register Now</button>
            </section>
           
            <div >
             <div class="dralready">Already have an account ? <a className="dralready1" onClick={() => navigate('/dashboard')}> Login</a></div>
            </div>
            
          
          </div>
        </div>

  
    
    
    <figure className='drregistrationimg'>
    <img src={drregistrationimg} alt="" />
   
    </figure>
  </form>
     


     
    </>
  );
};

export default Drregistration;





@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.drregistrationbox {
  background-color: white;
  width: 52%;
  height: 59%;
  position: absolute;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  right: 5px;
  justify-content: space-around;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  top: 25%;
  left: 24%;
}
.drregistrationimg {
  width: 11%;
  height: 434px;
  position: absolute;
  left: 694px;
  top: 185px;
}

.drregistrationimg img {
  height: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 15px;
  width: 280%;
}


.registrationformbox {
  padding: 1px 25px;
  display: flex;
  flex-direction: column;
  width: 40%;
}
.registrationformbox h3 {
  font-size: 23px;
  font-weight: 500;
  position: absolute;
  top: 4%;
}
.registrationformbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(109, 189, 247);
  position: absolute;
  left: 0px;
  bottom: 2px;
  border-radius: 5px;
}


.input-field1 {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
  bottom: 3%;
}
.input-field1 input {
  display: block;
  width: 100%;
  padding: 10px 5px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 15px;
}

.drregisterbtn {
  position: relative;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  top: 50%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 100%;
  background-color: rgb(109, 189, 247);
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.55) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}
.drregisterbtn:hover,
.drregisterbtn:focus {
  background-color: rgb(73, 172, 230);
}
.dralready{
  color: #333;
  top: 91%;
  display: flex;
  position: absolute;
  cursor: pointer;
 gap: 6px;
 
 }
 .dralready1{
  text-decoration: none;
  color: black;
 
 }
 .policy{
  display: flex;
  align-items: center;
  top: 71%;
  position: absolute;

} */


