@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.error{
    color: rgb(192, 37, 37);
  font-size: 0.875rem;

}
.success-message {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .checkmark {
    color: #4CAF50;
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .success-message h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .success-message p {
    color: #666;
    margin-bottom: 10px;
  }
  
  .success-message .note {
    font-size: 0.9em;
    color: #999;
    margin-top: 20px;
  }



















.fillpatient{
    font-size: 20px;
    font-weight: 400;
    padding: 5px 0px ;
    margin-bottom: 10px;
    
}


.appointment-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 48px;
    flex-wrap: wrap;
}

.appointment-wrapper {
    margin: 0 auto;
    max-width: 550px;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.appointment-form-group {
    margin-bottom: 20px;
}


.appointment-input{
    max-width: 700px;
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
}
.appointment-input-gender{
    max-width: 800px;
    width: 100%;
    padding: 12px 24px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    background: white;
    font-weight: 500;
    font-size: 16px;
    color: #6b7280;
    outline: none;
    resize: none;
}

.appointment-input:focus {
    border-color: rgb(31, 160, 160);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}



@media (min-width: 540px) {
    .form-group-half {
        width: 100%;
        max-width: 800px;
    }
    .appointment-input{
       max-width: 540px;
        width: 100%;
        padding: 12px 24px;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
        background: white;
        font-weight: 500;
        font-size: 16px;
        color: #6b7280;
        outline: none;
        resize: none;
    }
    .appointment-input-gender{
        max-width: 800px;
        width: 99%;
        padding: 12px 24px;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
        background: white;
        font-weight: 500;
        font-size: 16px;
        color: #6b7280;
        outline: none;
        resize: none;
    }
  

}

.appointment-button{
    margin-top: 0%;
    text-align: center;
    font-size: 20px;
    border-radius: 6px;
    padding: 11px 20px;
    border: none;
    font-weight: 500;
    color: white;
    width: 100%;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
    background-color: rgb(53, 188, 188);
    background-color: #38d2d2;
    background-image: radial-gradient(
        93% 87% at 87% 89%,
        rgba(0, 0, 0, 0.23) 0%,
        transparent 86.18%
      ),
      radial-gradient(
        66% 66% at 26% 20%,
        rgba(255, 255, 255, 0.55) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      );
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
      inset 0px 3px 9px rgba(255, 255, 255, 0.3),
      inset 0px 1px 1px rgba(255, 255, 255, 0.6),
      inset 0px -8px 36px rgba(0, 0, 0, 0.3),
      inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  
}

.appointment-button:hover,
.appointment-button:focus {
  background-color: rgb(26, 176, 176);
}



