
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}





.main--content {
  position: absolute;
  top: 13%;
  left: 265px;
  transition: .3s;
  width: calc(100% - 290px);
  padding: 10px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.card {
  padding: 20px;
  border-radius: 20px;
  flex: 1 1 calc(25% - 20px);
  min-width: 200px;
  transition: .3s;
}

.card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card--data {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.card h1 {
  font-size: 30px;
  margin-top: 10px;
}

.card--icon--lg {
  font-size: 60px;
}


.ri-calendar-2-line {
  font-size: 60px;
  color: rgb(136, 127, 238);
}
.pres-icon{
  font-size: 48px;
  color: rgb(109, 189, 247);
}
.card--stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.card--stats span {
  display: flex;
  align-items: center;
}

.card--icon {
  margin-right: 5px;
}

.stat--icon {
  color: #5f5ce0;
}

.up--arrow {
  color: #70d7a5;
}

.down--arrow {
  color: #e86786;
}

.card-1 {
  background-color: rgba(80, 115, 251, .1);
}

.card-1 .card--title {
  color: rgb(125, 115, 231);
}

.card-1 .card--icon--lg {
  color: rgba(80, 115, 251, .4);
}

.card-2 {
  background-color: rgba(241, 210, 67, .1);
}

.card-2 .card--title {
  color: rgba(241, 210, 67, 1);
}

.card-2 .card--icon--lg {
  color: rgba(241, 210, 67, .4);
}

.card-3 {
  background-color: rgba(112, 215, 165, .1);
}

.card-3 .card--title {
  color: rgba(112, 215, 165, 1);
}

.card-3 .card--icon--lg {
  color: rgba(112, 215, 165, .4);
}

.card-4 {
  background-color: rgba(109, 189, 247, 0.1);
}

.card-4 .card--title {
  color: rgb(109, 189, 247);
  
}

.card-4 .card--icon--lg {
  color: rgba(116, 106, 227, 0.4);
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1200px) {
  .card {
    flex: 1 1 calc(50% - 20px);
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
  .main--content {
    position: absolute;
    top: 13%;
    left: 144px;
    transition: .3s;
    width: calc(100% - 290px);
    padding: 10px;
  }
}


@media screen and (max-width: 768px) {
  .main--content {
    left: 0%;

    width: 100%;
  }

  .card {
    flex: 1 1 100%;
  }
}

@media screen and (max-width: 480px) {

  .card h1 {
    font-size: 24px;
  }

  .card--icon--lg {
    font-size: 60px;
  }

  .card--stats {
    flex-direction: column;
    align-items: flex-start;
  }

  .card--stats span {
    margin-bottom: 5px;
  }
}













.dashboard-main-content {
  padding: 0px ;
  margin-top: 14%;
  max-width: 1180px;
  margin-left: 18%;
  margin-right: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.col {
  flex: 1 1 calc(50% - 10px);
  min-width: 300px;
}

.graph-chart,
.patient-review-container {
  background-color: white;
  border-radius: 10px 50px;
  padding: 20px 25px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  height: 100%;
}

.graph-chart:hover,
.patient-review-container:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.chart-heading {
  color: #333;
  margin-bottom: 20px;
}

.donut-chart {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  margin: 4% auto;
}

.slice {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 200px, 200px, 100px);
}

.slice.total {
  background-color: #4169E1;
  transform: rotate(0deg);
}

.slice.new {
  background-color: #FFD700;
  transform: rotate(90deg);
}

.slice.old {
  background-color: #E6E6FA;
  transform: rotate(180deg);
}

.chart-center {
  position: absolute;
  width: 60%;
  height: 60%;
  background-color: white;
  border-radius: 50%;
  top: 20%;
  left: 20%;
}

.chart-patient-paragrapgh {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chart-patient-paragrapgh-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.color-box {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 6px;
}

.color-box.new {
  background-color: #FFD700;
}

.color-box.old {
  background-color: #E6E6FA;
}

.color-box.total {
  background-color: #4169E1;
}

.review-type {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.review-items {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.review-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
}

.review-label {
  font-size: 16px;
  font-weight: bold;
  width: 0%;
  color: #333;
}

.review-bar {
  width: 80%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  
 
}

.review-bar-fill {
  height: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 1200px) {
  .dashboard-main-content {
    margin-left: 5%;
    margin-right: 5%;
    
  }
}


@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col {
    flex: 1 1 100%;
  }

  .graph-chart,
  .patient-review-container {
    margin-bottom: 20px;
  }

  .review-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .review-label {
    width: 100%;
    margin-bottom: 5px;
  }

  .review-bar {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-main-content {
    margin-top: 20%;
    padding: 10px;
  }

  
}

















/* 

.review {
      display: flex;
     align-items: center;
     flex-direction: column;
    
    }
   .stars {
     display: flex;
     flex-direction: row;
    }
    .namereview{
        border: 1px solid #a9a9a9;
        border-radius: 5px;
        padding: 10px;
        margin: 10px;
        min-height: 10px;
        width: 300px;
      
      
    }
    .textarea {
    border: 1px solid #a9a9a9;
      border-radius: 5px;
      padding: 10px;
      margin: 20px 0;
      min-height: 100px;
      width: 300px;
      resize: none;
    }
    .reviewsubmit {
     border: 1px solid #a9a9a9;
     border-radius: 5px;
     width: 325px;
     padding: 10px;
    } */





    /* Doctor Dashboard */

/*
    .main--content {
    position: absolute;
    top: 13%;
    left: 20%;
    transition: .3s;
}
.main--content.active {
    width: calc(100% - 103px);
}
.cards {
    display: flex;
    gap: 36px;
}
.card {
    padding: 20px;
    border-radius: 20px;
    min-width: 230px;
    height: auto;
    transition: .3s;
}
.card:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card--data {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.card h1 {
    font-size: 30px;
    margin-top: 10px;
}
.card--icon--lg {
    font-size: 80px;
}
.ri-calendar-2-line{
  font-size: 80px;
  color: rgb(136, 127, 238);
}
.card--stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}
.card--stats span {
    display: flex;
    align-items: center;
}
.card--icon {
    margin-right: 5px;
}
.stat--icon {
    color: #5f5ce0;
}
.up--arrow {
    color: #70d7a5;
}
.down--arrow {
    color: #e86786;
}
.card-1 {
    background-color: rgba(80, 115, 251, .1);
}
.card-1 .card--title {
    color: rgb(125, 115, 231);
}
.card-1 .card--icon--lg {
    color: rgba(80, 115, 251, .4);
}
.card-2 {
    background-color: rgba(241, 210, 67, .1);
}
.card-2 .card--title {
    color: rgba(241, 210, 67, 1);
}
.card-2 .card--icon--lg {
    color: rgba(241, 210, 67, .4);
}
.card-3 {
    background-color: rgba(112, 215, 165, .1);
}
.card-3 .card--title {
    color: rgba(112, 215, 165, 1);
}
.card-3 .card--icon--lg {
    color: rgba(112, 215, 165, .4);
}

.card-4 {
    background-color: rgba(106, 106, 227, 0.1);
}
.card-4 .card--title {
    color: rgb(116, 106, 227);
}
.card-4 .card--icon--lg {
    color: rgba(116, 106, 227, 0.4);
}









.main-content {
    padding: 20px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .col {
    flex: 1;
  }
  
  .graph-chart {
    background-color: white;
    border-radius: 10px;
    padding: 5px 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    max-width: 530px;
    margin-left: 0%;
 
  }
  .graph-chart:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

  
  .patient-review-container {
    background-color: white;
    border-radius: 10px;
    padding: 13px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    max-width: 560px;
    margin-left: 4%;
  }
  .patient-review-container:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
  .chart-heading {
    color: #333;
  }
  
  .donut-chart {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
  }
  
  .slice {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    clip: rect(0, 200px, 200px, 100px);
  }
  
  .slice.total {
    background-color: #4169E1;
    transform: rotate(0deg);
  }
  
  .slice.new {
    background-color: #FFD700;
    transform: rotate(90deg);
  }
  
  .slice.old {
    background-color: #E6E6FA;
    transform: rotate(180deg);
  }
  
  .chart-center {
    position: absolute;
    width: 60%;
    height: 60%;
    background-color: white;
    border-radius: 100%;
    top: 20%;
    left: 20%;
  }
  
  .chart-patient-paragrapgh {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
  }
  
  .chart-patient-paragrapgh-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .color-box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .color-box.new {
    background-color: #FFD700;
  }
  
  .color-box.old {
    background-color: #E6E6FA;
  }
  
  .color-box.total {
    background-color: #4169E1;
  }
  
  .review-type {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .review-items {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  
  .review-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .review-label {
    font-size: 16px;
    font-weight: bold;
    width: 20%;
    color: #333;
  }
  
  .review-bar {
    width: 80%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
  }
  
  .review-bar-fill {
    height: 100%;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .graph-chart,
    .patient-review-container {
      max-width: 100%;
    }
  
    .review-label {
      font-size: 14px;
      width: 30%;
    }
  
    .review-bar {
      width: 70%;
    }
  }

*/