@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
.top-to-btm {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #38D2D2;
  border-radius: 10%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
}

.top-to-btm:hover {
  opacity: 1;
}



.top-to-btm span {
  display: block;
  font-size: 14px;
  color: white;
  margin-top: 25%;
}
.ri-arrow-up-s-line{
  color: white;
  font-size: 30px;
  position: absolute;
  top: 0%;
}



.home-section {
  position: relative;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}

.appointment-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.appointment {
  width: 100%;
    left: 0;
    height: 540px;
    position: absolute;
    top: 0%;
    
}

.home-content-wrapper {
  position: absolute;
  top: -2%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5%;
}

.appointment-text {
  color: #fff;
  
}

.appointment-text h1 {
  font-size: 3vw;
  font-weight: bold;

}



.appointment-text p {
  font-size: 2vw;
  font-weight: bold;
}

.appointment-search-container {
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 2px 16px 30px rgba(0, 0, 0, 0.12);
  margin-top: 4%;
}

.appointment-search {
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
  grid-gap: 20px;
}

.appo-search {
  display: flex;
  align-items: center;
  background-color: #f7fbff;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
}

.appo-search input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1.1rem;
  margin-left: 10px;
}

.book-btn {
  width: 100%;
  padding: 15px;
  color: #fff;
  background-color: #38D2D2;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}

.book-btn:hover,
.book-btn:focus {
  background-color: rgb(26, 176, 176);
}

@media screen and (max-width: 1200px) {
  .appointment-text h1 {
    font-size: 4vw;
  }

  .appointment-text p {
    font-size: 3vw;
  }
  .home-section {
    height: 60vh;
    
  }
}
@media screen and (max-width: 1024px) {
  .home-section {
   
    height: 45vh;
    
  }
}
@media screen and (max-width: 821px) {
  .home-section {
   
    height: 50vh;
    
  }
}
@media screen and (max-width: 768px) {
  .home-section {
   
    height: 60vh;
    
  }
  .appointment-text h1 {
    font-size: 5vw;
  }

  .appointment-text p {
    font-size: 4vw;
  }

  .appointment-search {
    grid-template-columns: 1fr;
  }

  .book-btn {
    padding: 10px;
  }
}

@media screen and (max-width: 480px) {
  .home-section {
   
    height: 70vh;
    
  }
  .appointment-text h1 {
    font-size: 7vw;
  }

  .appointment-text p {
    font-size: 5vw;
  }

  .appointment-search-container {
    padding: 15px;
  }

  .appo-search {
    padding: 10px;
  }

  .appo-search input {
    font-size: 0.9rem;
  }

  .book-btn {
    font-size: 14px;
  }
}



#specialities {
  padding: 0%;
}

.specialities-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
}

.specialities-left {
  flex: 1;
}

.Specialities {
  color: rgb(26, 187, 187);
  font-size: 3.5rem;
  font-weight: inherit;
  margin-bottom: 1rem;
}

.line {
  width: 13%;
  height: 3px;
  background-color: rgb(26, 187, 187);
  margin-bottom: 2rem;
}



.specialities-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.speciality-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.speciality-item img {
  width: 60px;
  height: 60px;
}

.speciality-item span {
  color: rgb(23, 23, 116);
  font-size: 0.9rem;
}

.view {
  display: inline-block;
  margin-top: 1rem;
  color: rgb(23, 23, 116);
  font-weight: 600;
  text-decoration: none;
  margin-top: 2rem;
}

.specialities-right {
  flex: 1;
  position: relative;
}

.finddrimg {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.overlay-content {
  position: absolute;
  top: 25%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
}

.overlay-content h1 {
  font-size: 1.5rem;
  margin-bottom: 0.15rem;
}

.overlay-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.button-1 {
  background-color: rgb(18, 139, 139);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 5rem;

}

.button-1:hover {
  background-color: rgb(5, 112, 112);
}

@media (max-width: 1024px) {
  #specialities {
    padding: 0%;
  }
  .Specialities {
    margin-left: 2rem;
  }
  .line {
    margin-left: 2rem;
  }
  
.view {
  margin-left: 2rem;
}
  .specialities-grid {
   
    margin-left: 2rem;
  }
  .specialities-container {
    flex-direction: column;
  }

  .specialities-right {
    margin-top: 2rem;
  }
  .finddrimg {
    width: 70%;
    height: auto;
    margin-top: 2rem;
   margin-left: 7rem;
  }
   .button-1 {
    font-size: 1rem;
    padding: 8px 15px;
  
  }
}
@media (max-width: 872px) {
  #specialities {
    padding: 0%;
  }

  .overlay-content h1 {
    margin-left: 4rem;
  }

  .overlay-content p {
    margin-left: 4rem;
  }

  .button-1 {
    font-size: 1rem;
    padding: 8px 15px;
    margin-left: 3rem;
  
  }
}
@media (max-width: 768px) {
  #specialities {
    padding: 0%;
  }
  .specialities-grid {
    grid-template-columns: 1fr;
    margin-left: 2rem;
  }

  .overlay-content h1 {
    margin-left: 4rem;
  }

  .overlay-content p {
    margin-left: 4rem;
  }

  .button-1 {
    font-size: 1rem;
    padding: 8px 15px;
    margin-left: 3rem;
  
  }
  .finddrimg {
    width: 70%;
    height: auto;
    margin-top: 2rem;
   margin-left: 4rem;
  }
}


@media (max-width: 670px) {
  
  #specialities {
    padding: 0%;
  }
.overlay-content h1 {
  margin-left: 0rem;
  margin-top: 2rem;
  font-size: 0.8rem;
}

.overlay-content p {
  margin-left: 4rem;
  font-size: 0.8rem;
}

.button-1 {
  font-size: 0.8rem;
  padding: 6px 10px;
  margin-left: 5rem;

}
}

@media (max-width: 480px) {
  #specialities {
    padding: 0%;
 
  }
  .Specialities {
    font-size: 1.9rem;
    margin-top: 5%;
  }

  .speciality-item span {
    font-size: 0.8rem;
  }

  .overlay-content h1 {
    font-size: 0.7rem;
    margin-left: 2rem;
    margin-top: 15%;
  }

  .overlay-content p {
    font-size: 0.7rem;
    margin-left: 5rem;
    margin-bottom: 0%;
  }

  .button-1 {
    font-size: 0.6rem;
    padding: 6px 10px;
    margin-left: 5rem;
  }
}
@media (max-width: 412px) {
  #specialities {
    padding: 0%;
  }
  .overlay-content h1 {
    font-size: 0.5rem;
    margin-left: 2rem;
    margin-top: 15%;
  }

  .overlay-content p {
    font-size: 0.5rem;
    margin-left: 4.5rem;
    margin-bottom: 0%;
  }

  .button-1 {
    font-size: 0.5rem;
    padding: 6px 10px;
    margin-left: 7rem;
  }
}










.services-section {
  padding: 50px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.main-heading {
  text-align: center;
  font-size: 36px;
  color: rgb(83, 80, 80);
  margin-bottom: 20px;
}

.sub-heading {
  text-align: center;
  color: rgb(88, 85, 85);
  margin-bottom: 50px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.home-service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.service-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.service-text h3 {
  color: rgb(88, 85, 85);
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
}

@media (max-width: 1024px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .services-grid {
    grid-template-columns: 1fr;
  }

  .service-item {
    flex-direction: row;
    text-align: left;
    gap: 20px;
  }

  .service-image {
    width: 30%;
    margin-bottom: 0;
  }

  .service-text {
    flex: 1;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 28px;
  }

  .sub-heading {
    font-size: 14px;
  }

  .service-item {
    flex-direction: column;
    text-align: center;
  }

  .service-image {
    width: 50%;
    margin-bottom: 20px;
  }
}







  /* Home Appointment Done */
/*   
/*   
 
  
  
  
  
   
  
  /* Home Specialities Done */
  /*
  .mainheading{
    top: 130px;
    position: relative;
    justify-content: center;
   display: flex;
    font-size: 25px;
   color: rgb(83, 80, 80);
   }
  .mainheading1{
    top: 90px;
    position: relative;
    justify-content: center;
   display: flex;
  
  
  }
  .mainheading2{
   display: flex;
   justify-content: center;
   text-align: center;
  }
  
  .schedule{
  position: absolute;
  color: rgb(88, 85, 85);
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  left: 10%;
  top: 1650px;
  }
  .schedule1{
    position: absolute;
    color: rgb(88, 85, 85);
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    left: 10%;
    top: 1675px;
    }
    .schedule2{
      position: absolute;
      color: rgb(88, 85, 85);
      padding: 20px;
      font-size: 16px;
      font-weight: bold;
      left: 10%;
      top: 1700px;
      }
      .schedulepic{
      width: 15%;
      height: 30%;
      position: absolute;
      left: 20%;
      top: 1580px;
      }
      .doctor{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 37%;
        top: 1650px;
  
      }
      .doctor1{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 37%;
        top: 1675px;
        
      }
      .doctorpic{
        width: 15%;
        height: 30%;
        position: absolute;
        left: 44%;
        top: 1580px;
      }
      .pro{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 61%;
        top: 1650px;
  
      }
      .pro1{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 61%;
        top: 1675px;
        
      }
      .pro2{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 61%;
        top: 1700px;
        
      }
      .propic{
        width: 15%;
        height: 30%;
        position: absolute;
        left: 73%;
        top: 1580px;
        
      }
      .prescribedpic{
      width: 15%;
      height: 25%;
      position: absolute;
      left: 10%;
      top: 1880px;
      }
      .prescribed{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 27%;
        top: 1900px;
      }
      .prescribed1{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 27%;
        top: 1925px;
      }
      .prescribed2{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 25%;
        top: 1950px;
      }
      .medicinespic{
        width: 15%;
        height: 30%;
        position: absolute;
        left: 37%;
        top: 1880px;
      }
      .medicines{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 52%;
        top: 1900px;
      }
      .medicines1{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 52%;
        top: 1925px;
      }
      .regularpic{
        width: 15%;
        height: 30%;
        position: absolute;
        left: 61%;
        top: 1880px;
      }
      .regular{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 76%;
        top: 1900px;
      }
      .regular1{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 76%;
        top: 1925px;
      }
      .regular2{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 76%;
        top: 1950px;
      }
      .regular3{
        position: absolute;
        color: rgb(88, 85, 85);
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        left: 76%;
        top: 1975px;
      }
   */











      /*
























      
import "../css/home.css"

import appointmentimg from './image/appointmentimg.jpg'
import { GoSearch } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import finddr from './image/finddr.png'
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
    <section>
      <div><img className='appointment' src={appointmentimg} alt="" /></div>

      <div className="appointmenttext"><h1>Get 10% discount</h1></div>
      <div className="appointmenttext2">on all appointments*</div>
      <div className="appointmenttext3">booked and paid online.</div>
            
        
     </section>

     <section>
     <div className='appointmentsearchcontainer'>
      
      <div className="appointmentsearch">

      <div className="apposearch">
      <GoSearch />
        <input type="text" placeholder='Search for doctor or speciality' />
      </div>

      <div className="apposearch">
      <SlLocationPin />
        <input type="text" placeholder='Search Location' />
      </div>

     <div> <button className='bookbtn'><b>Search</b></button></div>

      </div>

      </div>
     </section >

    <section id="specialities">
        <div id="specialities-link">
      <div className="Specialities"><h1>Specialities</h1></div>
      <div className="line"></div><div className="line2"></div>
      <div className='cancer'><img src="https://www.maxhealthcare.in/img/cancer%20care%20oncology.svg" alt="" /></div>
      <div className="cancercare">Cancer Care / Oncology</div>

      <div className='robotic'><img src="https://www.maxhealthcare.in/img/The%20Da%20Vinci%20Xi%20Robotic%20Surgery.svg" alt="" /></div>
      <div className="roboticsurgery">Robotic Surgery</div>

      <div className='cardiac'><img src="https://www.maxhealthcare.in/img/cardia.svg" alt="" /></div>
      <div className="cardiacscience">Cardiac Sciences</div>

      <div className='liver'><img src="https://www.maxhealthcare.in/img/Liver%20Transplant%20and%20bs.svg" alt="" /></div>
      <div className="livertrans">Liver Transplant</div>

      <div className='joint'><img src="https://www.maxhealthcare.in/img/Orthopaedics.svg" alt="" /></div>
      <div className="jointrep">Joint Replacement</div>

      <div className='neuro'><img src="https://www.maxhealthcare.in/img/Neurosciences.svg" alt="" /></div>
      <div className="neuroscience">Neurosciences</div>

      <div className='gas'><img src="https://www.maxhealthcare.in/img/Gastroenterology.svg" alt="" /></div>
      <div className="gastro">Gastroenterlogy</div>

      <div className='thor'><img src="https://www.maxhealthcare.in/img/Thoracic%20Surgery.svg" alt="" /></div>
      <div className="thoracic">Thoracic</div>

      <div ><a className='view'   >View all &gt;</a></div>

        </div>
        <div className="specialities-images">
          <div><img className='finddrimg' src={finddr} alt="" /></div>
          <div className="heading"><h1>Looking for an Expert</h1></div>
          <div className="heading1">Medical Healthcare is home to some</div>
          <div className="heading2">of the eminent doctors.</div>

          <div> <button onClick={() => navigate('/doctors')} class="button-1" role="button">Find a Doctor   &gt;</button> </div>
        </div>
     </section>

     <section >

      
     <div className='mainheading'><h1>Our Services</h1></div>
     <div className='mainheading1'><p>Our medical team in prepared to handle a wide range of medical needs, such as primary <br /> care,
      and specialist consultations. We have the knowledge and experience to meet all of <br /> 
    <div className='mainheading2'>your healthcare needs.</div></p></div>


      <div className='schedule'>Schedule your</div>
      <div className='schedule1'>appointment via</div>
      <div className='schedule2'>call/chat</div>
      <div><img className='schedulepic' src="https://www.kynohealth.com/static/media/service1.65a8f59e52f0ff3433ee.png" alt="" /></div>
     
      <div className='doctor'>Doctors at</div>
      <div className='doctor1'>your home</div>
      <div><img className='doctorpic' src="https://www.kynohealth.com/static/media/service2.9226a43b3d5bf9f14752.png" alt="" /></div>

      <div className='pro'>Prolonged, detailed</div>
      <div className='pro1'>and personalized</div>
      <div className='pro2'>health check ups</div>
      <div><img className='propic' src="https://www.kynohealth.com/static/media/service3.711c5d4c71aa822c26fd.png" alt="" /></div>
       
      <div><img className='prescribedpic' src="https://www.kynohealth.com/static/media/service4.e9f544739fe2dbfc58cb.png" alt="" /></div>
      <div className='prescribed'>Prescribed</div>
      <div className='prescribed1'>diagnostic</div>
      <div className='prescribed2'>tests available</div>
        

      <div><img className='medicinespic' src="https://www.kynohealth.com/static/media/service5.da64ae869f4f955262b4.png" alt="" /></div>
      <div className='medicines'>Medicines</div>
      <div className='medicines1'>available</div>

      <div><img className='regularpic' src="https://www.kynohealth.com/static/media/service6.305f70098bfd11b54ced.png" alt="" /></div>
      <div className='regular'>Regular and timely</div>
      <div className='regular1'>follow-ups are done</div>
      <div className='regular2'>to ensure complete</div>
      <div className='regular3'>recovery</div>
       

     </section>

  

    </>
  )
}

export default Home;















  
.appointment{
   
    width: 100%;
    height: 100%;
    position: relative;
  
  }
  .appointmenttext{
  position: absolute;
  top: 23%;
  color:  #ffffff;
  padding: 20px;
  font-size: 30px;
  font-weight: bold;
  left: 1%;
  }
  .appointmenttext2{
    position: absolute;
    top: 38%;
    color:  #ffffff;
    padding: 20px;
    font-size: 30px;
    font-weight: bold;
    left: 1%;
  }
  .appointmenttext3{
    position: absolute;
    top: 45%;
    color:  #ffffff;
    padding: 20px;
    font-size: 30px;
    font-weight: bold;
    left: 1%;
  }
  
  
  
  .appointmentsearchcontainer{
    width: 53%;
    background-color: #ffffff;
    padding: 30px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 2px 16px 30px rgba(0, 0, 0, 0.12);
    z-index: 3;
    position: absolute;
    top: 62%;
    left: 2%;
  
  }
  .appointmentsearch{
    display: grid;
    grid-template-columns: 1fr 1fr 100px;
    grid-gap: 40px;
  }
  .apposearch{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7fbff;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    left: 10%;
  }
  .apposearch input{
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  
  }
  .bookbtn{
      background-color: rgb(53, 188, 188);
      padding: 15%;
      color: #fff;
      padding: 18px 25px;
      background-color: #38D2D2;
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
      box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      font-weight: bold;
      font-size: 16px;
      border: 0;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      cursor: pointer;
    }
    .bookbtn:hover,
    .bookbtn:focus {
      background-color: rgb(26, 176, 176);
    
    }
  

  
  .Specialities{
    position: absolute;
    top: 700px;
    color:  rgb(26, 187, 187);
    padding: 20px;
    font-size: 25px;
    font-weight: lighter;
    left: 4%;
  }
  .line{
    width: 13%;
    height: 3px;
    background-color:  rgb(26, 187, 187);
    position: absolute;
    left: 80px;
    top: 815px;
  }
  .line2{
    width: 22%;
    height: 1px;
    background-color:  lightgray;
    position: absolute;
    left: 277px;
    top: 816px;
  }
  .cancer{
    position: absolute;
    top: 840px;
    padding: 20px;
    left: 4%;
  }
  .cancercare{
    position: absolute;
    top: 855px;
    padding: 20px;
    left: 8%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .robotic{
    position: absolute;
    top: 840px;
    padding: 20px;
    left: 24%;
  }
  .roboticsurgery{
    position: absolute;
    top: 855px;
    padding: 20px;
    left: 30%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .cardiac{
    position: absolute;
    top: 920px;
    padding: 20px;
    left: 4%;
   
  }
  .cardiacscience{
    position: absolute;
    top: 935px;
    padding: 20px;
    left: 8%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .liver{
    position: absolute;
    top: 920px;
    padding: 20px;
    left: 24%;
  }
  .livertrans{
    position: absolute;
    top: 935px;
    padding: 20px;
    left: 30%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .joint{
    position: absolute;
    top: 1000px;
    padding: 20px;
    left: 4%;
    
  }
  .jointrep{
    position: absolute;
    top: 1015px;
    padding: 20px;
    left: 8%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .neuro{
    position: absolute;
    top: 1000px;
    padding: 20px;
    left: 24%;
  }
  .neuroscience{
    position: absolute;
    top: 1015px;
    padding: 20px;
    left: 30%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .gas{
    position: absolute;
    top: 1080px;
    padding: 20px;
    left: 4%;
    
  }
  .gastro{
    position: absolute;
    top: 1095px;
    padding: 20px;
    left: 8%;
    color: rgb(23, 23, 116);
    font-size: 18px;
  }
  .thor{
    position: absolute;
    top: 1080px;
    padding: 20px;
    left: 24%;
  }
  .thoracic{
    position: absolute;
    top: 1095px;
    padding: 20px;
    left: 30%;
    color: rgb(12, 12, 130);
    font-size: 18px;
  }
  .view{
    text-decoration: none;
    position: absolute;
    top: 1215px;
    left: 5%;
    list-style: none;
   font-size: medium;
   font-weight: 600;
   color: rgb(23, 23, 116);
  
  }
  
  
  
  
  
  
  
  #specialities{
    display: flex;
    justify-content: space-between;
  }
  
  .finddrimg{
   
    margin-bottom: 30px;
    margin-left: 750px;
    width: 47%;
    max-width: 100%;
    position: relative;
    top: 100px;
    z-index: 10;
  }
  
  .heading{
    position: absolute;
    top: 750px;
    margin-left: 820px;
    z-index: 10;
    font-size: 20px;
    color: #ffffff;
  }
  .heading1{
    position: absolute;
    top: 830px;
    margin-left: 820px;
    z-index: 10;
    font-size: 20px;
    color: #ffffff;
  }
  .heading2{
    position: absolute;
    top: 860px;
    margin-left: 820px;
    z-index: 10;
    font-size: 20px;
    color: #ffffff;
  }
  
  .button-1 {
      background-color: rgb(18, 139, 139);
      padding: 15%;
      color: #fff;
      padding: 15px 25px;
      background-color: #1d8989;
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%,  rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
      box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      font-size: 22px;
      border: 0;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      cursor: pointer;
      position: absolute;
      top: 910px;
      margin-left: 820px;
      z-index: 10;
  }
  
  .button-1:hover,
  .button-1:focus {
    background-color: rgb(5, 112, 112);
  
  }
  
  
  
  
  
  
  

      */
  