@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.swal2-popup {
  border-radius: 10px;
}

.swal2-title {
  font-size: 24px;
  font-weight: bold;
}

.swal2-content {
  font-size: 16px;
}

.swal2-confirm {
  background-color: #4bc2d2 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #a5dc86;
}




.contact-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.hero-section {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 20px;
}

.contactimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8%;
}

.info-section, .form-section {
  flex: 1;
  min-width: 300px;
}

.contactbox {
  background-color: white;
  border-radius: 1em;
  padding: 20px;
  box-shadow: 0 0 15px rgba(220, 218, 218, 0.5);
}

.help, .quick {
  font-size: 30px;
  color: rgb(125, 123, 123);
  font-weight: 600;
  margin-bottom: 20px;
}

.info-text p {
  margin-bottom: 10px;
}

.contact-details {
  margin-top: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contacticon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.contactform {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.input_box input,
.input_massage_box input {
  width: 100%;
  max-width: 900px;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background: #f0f1f8;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.contactus-message{
 width: 100%;
  max-width: 900px;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background: #f0f1f8;
  border-radius: 6px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  resize: none;
  min-height: 90px;

}
.contactbutton {
  max-width: 800px;
 width: 100%;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none;
  background-color: rgb(53, 188, 188);
  background-color: #38D2D2;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
 
}

.contactbutton:hover,
.contactbutton:focus {
  background-color: rgb(26, 176, 176);
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-section {
   margin-top: 2rem;
  }
  
  .content-wrapper {
    flex-direction: column;
  }
  
  .hero-section {
    height: 200px;
  }
  
  .help, .quick {
    font-size: 25px;
    font-weight: 600;
  }
}
.input_box input,
  .input_massage_box input {
    font-size: 14px;
    max-width: 900;
  }
  
  .contactbutton {
    font-size: 16px;
    max-width: 800px;
    width: 100%;
   
    

  }

  

@media (max-width: 480px) {
  .hero-section {
    height: 150px;
  }
  
  .contactbox {
    padding: 15px;
  }
  
  .help, .quick {
    font-size: 25px;
    font-weight: 600;
  }
  
  .input_box input,
  .input_massage_box input {
    font-size: 14px;
  
  }
  .contactbutton {
    font-size: 16px;
    max-width: 400px;
    width: 100%;
   
    

  }
  
}
  














/*       

import React, { useState } from "react";
import "../css/contactus.css";
import contactimg from "./image/contactimg.png";
import locationcontact from "./image/locationcontact.png";
import callcontact from "./image/callcontact.png";
import mailcontact from "./image/mailcontact.png";
import axios from "axios";




const Contactus = () => {

const [name,nameSet] = useState('');  
const [email,emailSet] = useState('');
const [phone,phoneSet] = useState('');
const [message,massageSet] = useState('');

const nameHandler =(event)=>{
  nameSet(event.target.value)
  }
const emailHandler =(event)=>{
  emailSet(event.target.value)
}
const phoneHandler =(event)=>{
  phoneSet(event.target.value)
}
const messageHandler =(event)=>{
  massageSet(event.target.value)
}
const submitHandler =(event)=>{
event.preventDefault();
let contactusdata = {name,email,phone,message}
return axios.post('http://localhost:8080/contactus/contactus/',contactusdata).then((response)=>{
console.log(response);
})
}
  return (
    <>
      <section>
        <img className="contaxtimage" src={contactimg} alt="" />
      </section>

      <section>
        <div className="contactbox">
          <div className="help">HOW WE CAN HELP YOU ?</div>
          <p className="para">
            Welcome to Medical Health care ! Ensuring your health is our
            priority.
          </p>
          <p className="para1">
            Schedule your consultation with our expert physicians and
            specialists
          </p>
          <p className="para2">
            effortlessly. Simply choose your preferred date, time, and
            department.
          </p>
          <p className="para3">
            Our dedicated team will confirm your appointment promptly.
          </p>

          <div>
            <img className="loaction" src={locationcontact} alt="" />
          </div>

          <p className="add1">B 14-15 Udhyog Marg</p>
          <p className="add2">Sector 2 Noida</p>

          <div>
            <img className="call" src={callcontact} alt="" />
          </div>
          <p className="phone">+91-120-95828448</p>

          <div>
            <img className="mail" src={mailcontact} alt="" />
          </div>
          <p className="mail1">info@medicalhealthcare.com</p>
        </div>
      </section>

      <section>
        <div className="quick">Quick Enquiry</div>

        <form method="post" className="contactform" onSubmit={submitHandler}>
          <div className="input_box">
            <input type="text" placeholder="Name" name="name" onChange={nameHandler}
            id="name" data-required="true" data-error-message="Your Name Is Required"/>
          </div>
          <div className="input_box">
            <input type="text" placeholder="E-mail" name="email" onChange={emailHandler}
            id="email" data-required="true" data-error-message="Your E-mail Is Required"/>
          </div>
          <div className="input_box">
            <input type="text" placeholder="Phone" name="phone" onChange={phoneHandler}
            id="phone" data-required="true" data-error-message="Your Phone Is Required"/>
          </div>

          <div className="input_massage_box"  >
          <input type="text" placeholder="Message" name="message" onChange={messageHandler}
            id="message" data-required="true" data-error-message="Your Message Is Required"/>
          </div>

    
          <section>
              <button className="contactbutton">Send Now</button>
            </section>

        </form>

      </section>
    </>
  );
};

export default Contactus;
























.contaxtimage {
  top: 15%;
  width: 100%;
  height: 40%;
  position: absolute;
}
.contactbox {
  background-color: white;
  width: 90em;
  height: 30em;
  position: absolute;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  right: 5px;
  justify-content: space-around;
  box-shadow: 0.188em 0.188em 1.55em rgb(220, 218, 218);
  top: 60%;
  left: 40px;
}
.help {
  font-size: 40px;
  position: absolute;
  top: 10%;
  color: rgb(125, 123, 123);
  font-weight: 600;
  left: 8%;
}
.para {
  top: 20%;
  position: absolute;
  left: 8%;
}
.para1 {
  top: 26%;
  position: absolute;
  left: 8%;
}
.para2 {
  top: 32%;
  position: absolute;
  left: 8%;
}
.para3 {
  top: 38%;
  position: absolute;
  left: 8%;
}
.loaction {
  height: 70px;
  left: 90px;
  position: absolute;
  top: 48%;
}

.add1 {
  top: 48%;
  position: absolute;
  left: 12%;
}
.add2 {
  top: 52%;
  position: absolute;
  left: 12%;
}
.call {
  height: 70px;
  left: 110px;
  position: absolute;
  top: 65%;
}
.phone {
  top: 65%;
  position: absolute;
  left: 12%;
}
.mail {
  height: 70px;
  left: 105px;
  position: absolute;
  top: 80%;
}
.mail1 {
  top: 81%;
  position: absolute;
  left: 12%;
}
.quick {
  font-size: 30px;
  position: absolute;
  top: 68%;
  color: rgb(125, 123, 123);
  font-weight: 600;
  left: 55%;
}




.contactform {
 
  position: absolute;
  left: 55%;
  top: 75%;
}

.input_box input{
  height: 50px;
  width: 190%;
  border: none;
  background: #f0f1f8;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 15px;
  margin: 6px 0;
  
}
.input_massage_box input {
  height: 50px;
  width: 190%;
  border: none;
  background: #f0f1f8;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  padding: 0 15px ;
  min-height: 90px;
  margin: 6px 0;
 
}

.contactbutton {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  top: 100%;
  position: absolute;
  width: 202%;
  background-color: rgb(53, 188, 188);
  background-color: #38D2D2;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}
.contactbutton:hover,
.contactbutton:focus {
  background-color: rgb(26, 176, 176);

}


*/
















