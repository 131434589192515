@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

.doctor-patient-list {
  border-collapse: collapse;
  width: 80%;
  left: 270px;
  position: absolute;
  top: 10%;padding: 20px;
}

.table-container {
  overflow-x: auto;
}

.patient-details {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.patient-details th, 
.patient-details td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  
}

.patient-details th {
  background-color: #f8f8f8;
  font-weight: bold;
}

@media screen and (max-width: 1330px) {
  
  
.doctor-patient-list {

  width: 75%;
  left: 280px;
  
}
}

@media screen and (max-width: 1180px) {
  
  
  .doctor-patient-list {
  
    width: 75%;
    left: 260px;
    
  }
  }
@media screen and (max-width: 1024px) {
  .patient-details {
    font-size: 14px;
  }
  
  .patient-details th, 
  .patient-details td {
    padding: 10px;
  }
  
.doctor-patient-list {

  width: 100%;
  left: 0%;
  
}
}

@media screen and (max-width: 768px) {
  .patient-details {
    border: 0;
  }
  
  .patient-details thead {
    display: none;
  }
  
  .patient-details tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 3px solid #ddd;
    
  }
  
  .patient-details td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
 
  .patient-details td:last-child {
    border-bottom: 0;
  }
  
  .patient-details td::before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
}
















  /* import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/doctorappointmentlist.css'


const Doctorappointmentlist = () => {
  const [userlist, setuserlist] = useState('');




  useEffect(()=>{
  userlistData()
  
  
  },[]);
  
  
  const userlistData=()=>{
      return axios.get('http://localhost:8080/appointment/appointmentlist/').then((response)=>{
        setuserlist(response.data.message)
      }) 
  }
  return (
    <>
 


      
<table className='appointmentdoctor-details'>
  <tr>
    <th>Id</th>
    <th>Full Name</th>
    <th>Age</th>
    <th>Gender</th>
    <th>Mobile</th>
    <th>Date</th>
    <th>Address</th>
  </tr>

{userlist && userlist.map((user)=>
  <tr>
    <td>{user.id}</td>
    <td>{user.fullname}</td>
    <td>{user.age}</td>
    <td>{user.gender}</td>
    <td>{user.mobile}</td>
    <td>{user.date}</td>
    <td>{user.address}</td>
  </tr>
  )}

</table>
    </>
  )
}

export default Doctorappointmentlist;












@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.appointmentdoctor-details {
    border-collapse: collapse;
    width: 75%;
    left: 21%;
    position: absolute;
    top: 16%;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #DDD;
    width: 1%;
    
  }
  tr:hover {background-color: #F7F4F4;} */

