@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .doctors-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .search-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .group {
    position: relative;
    width: 25%;
  }
  
  .input-search {
    width: 100%;
    height: 40px;
    padding: 5px 1rem 5px 2.5rem;
    border: 1px solid #153975;
    border-radius: 8px;
    background-color: #fff;
    transition: .3s ease;
  }
  
  .input-search::placeholder {
    color: #153975;
    font-size: 15px;
    font-weight: 545;
  }
  
  .input-search:focus, .input-search:hover {
    outline: none;
    border-color: #153975;
    background-color: #fff;
  }
  
  .doctor-search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    fill: #153975;
    width: 1rem;
    height: 1rem;
  }
  
  .doctor-filters, .doctor-Available {
    color: #153975;
    font-size: 16px;
    font-weight: bold;
  }
  
  .dropdown-container {
    width: 250px;
    margin-top: 10px;
    position: relative;
  }
  
  .dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .arrow {
    font-size: 12px;
  }
  
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-top: none;
    background-color: white;
    border-radius: 0 0 5px 5px;
    z-index: 1000;
  }
  
  .search-box {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
  }
  
  .search-item {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .search-items {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .search-items label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .search-items input[type="checkbox"] {
    margin-right: 8px;
  }
  
  .doctors-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 2%;
  }
  
  .doctor-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 2px 2px 8px #00000029;
    display: flex;
    flex-direction: column;
  }
  
  .doctor-info {
    display: flex;
    margin-bottom: 15px;
  }
  
  .doctor-image {
    width: 100px;
    height: 100px;
    border-radius: 6px;
    margin-right: 15px;
    overflow: hidden;
    border: 1px solid #ccc;
  }
  
  .doctor-details {
    flex: 1;
  }
  
  .doctor-name {
    color: #153975;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .doctor-title {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .doctor-specialties {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .doctor-metadata {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .bookappointmentbutton {
    align-self: flex-end;
    padding: 13px 20px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border: none;
    background-color: #38D2D2;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;
    background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .bookappointmentbutton:hover,
  .bookappointmentbutton:focus {
    background-color: rgb(26, 176, 176);
  }
  
  @media (max-width: 768px) {
    .search-and-filters {
      flex-direction: column;
      align-items: stretch;
    }
  
    .group {
      width: 100%;
      margin-bottom: 15px;
    }
  
    .dropdown-container {
      width: 100%;
    }
  
    .doctors-list {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .doctor-info {
      flex-direction: column;
    }
  
    .doctor-image {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 15px;
    }
  
    .doctor-metadata {
      flex-direction: column;
    
    }
  }














/* 
.doctor-card3{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 75%;
    left: 28%;
}
.doctorpic3{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name3{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail3{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail3{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience3{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years3{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender3{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail3{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}











.doctor-card4{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 75%;
    left: 60%;
}
.doctorpic4{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name4{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail4{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail4{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience4{
    position: absolute;
    left: 32%;
    top: 48%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years4{
    position: absolute;
    left: 49%;
    top: 48%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender4{
    position: absolute;
    left: 32%;
    top: 56%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail4{
    position: absolute;
    left: 44%;
    top: 56%;
    font-size: 14px;
   font-size: 14px;
}









.doctor-card5{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 120%;
    left: 28%;
}
.doctorpic5{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name5{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail5{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail5{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience5{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years5{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender5{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail5{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}











.doctor-card6{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 120%;
    left: 60%;
}
.doctorpic6{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name6{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail6{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail6{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience6{
    position: absolute;
    left: 32%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years6{
    position: absolute;
    left: 49%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender6{
    position: absolute;
    left: 32%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail6{
    position: absolute;
    left: 44%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
}






.doctor-card7{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 165%;
    left: 28%;
}
.doctorpic7{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name7{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail7{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail7{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience7{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years7{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender7{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail7{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}












.doctor-card8{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 165%;
    left: 60%;
}
.doctorpic8{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name8{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail8{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail8{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience8{
    position: absolute;
    left: 32%;
    top: 55%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years8{
    position: absolute;
    left: 49%;
    top: 55%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender8{
    position: absolute;
    left: 32%;
    top: 63%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail8{
    position: absolute;
    left: 44%;
    top: 63%;
    font-size: 14px;
   font-size: 14px;
}



 */















































/*



import React from 'react'
import '../css/doctor.css';
import { useNavigate } from 'react-router-dom';
import doctorpic1 from './image/doctorpic1.png';
import doctorpic2 from './image/doctorpic2.png';
import doctorpic3 from './image/doctorpic3.png';
import doctorpic4 from './image/doctorpic4.png';
import doctorpic5 from './image/doctorpic5.png';
import doctorpic6 from './image/doctorpic6.png';
import doctorpic7 from './image/doctorpic7.png';
import doctorpic8 from './image/doctorpic8.png';



const Doctors = () => {
  const navigate = useNavigate();
  return (
    <>
    <div class="group">
  <svg class="doctor-search-icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
  <input placeholder="Search by Doctor" type="search" class="input"/>
</div>




    <div>
      <p className='doctor-filters'>Filters</p>
    </div>

    <div>
      <p className='doctor-Available'>Doctors Available</p>
    </div>

      <section>
        <div className="doctor-card">
        <img className='doctorpic1' src={doctorpic1} alt="" />
        <p className='doctor-name1'>Dr Ruchi Goyal</p>
        <p className='doctor-detail1'>Vice Chairman - Medical Oncology (Breast, Gynaecology, Thoracic)</p>
        <div className="doctorline1"></div>
        <p className='full-doctor-detail1'>Cancer Care / Oncology, Gynecologic Oncology, Thoracic Oncology, Medical Oncology, Breast Cancer</p>
        <div className="doctor-experience">Experience:</div>
       <div className="doctor-experience-years">12+ Years</div>
       <div className="doctor-gender">Gender:</div>
       <div className="doctor-gender-detail">Female</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>
      

      <section>
        <div className="doctor-card2">
          <img className='doctorpic2' src={doctorpic2} alt="" />
          <p className='doctor-name2'>Dr Swati Mishra</p>
        <p className='doctor-detail2'>Director - Nephrology</p>
        <div className="doctorline2"></div>
        <p className='full-doctor-detail2'>Nephrology, Kidney Transplant</p>
        <div className="doctor-experience2">Experience:</div>
       <div className="doctor-experience-years2">14+ Years</div>
       <div className="doctor-gender2">Gender:</div>
       <div className="doctor-gender-detail2">Female</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>

      <section>
        <div className="doctor-card3">
          <img className='doctorpic3' src={doctorpic3} alt="" />
          <p className='doctor-name3'>Dr. Naveen Kumar</p>
        <p className='doctor-detail3'>Principal Director - Interventional Cardiology</p>
        <div className="doctorline3"></div>
        <p className='full-doctor-detail3'>Cardiac Sciences, Cardiology, Interventional Cardiology, Cardiac Electrophysiology-Pacemaker</p>
        <div className="doctor-experience3">Experience:</div>
       <div className="doctor-experience-years3">8+ Years</div>
       <div className="doctor-gender3">Gender:</div>
       <div className="doctor-gender-detail3">Male</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>

      
      <section>
        <div className="doctor-card4">
          <img className='doctorpic4' src={doctorpic4} alt="" />
          <p className='doctor-name4'>Dr. Sunil Chaudhary</p>
        <p className='doctor-detail4'>Principal Director, Chief Surgeon & Head - Neonatal & Congenital Heart Surgery</p>
        <div className="doctorline4"></div>
        <p className='full-doctor-detail4'>Cardiac Sciences, Paediatrics (Ped), Paediatric (Ped) Cardiac Surgery</p>
        <div className="doctor-experience4">Experience:</div>
       <div className="doctor-experience-years4">11+ Years</div>
       <div className="doctor-gender4">Gender:</div>
       <div className="doctor-gender-detail4">Male</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>

      <section>
        <div className="doctor-card5">
          <img className='doctorpic5' src={doctorpic5} alt="" />
          <p className='doctor-name5'>Dr. Keshav Dewan</p>
        <p className='doctor-detail5'>Principal Director (Critical Care) & Director (Internal Medicine)</p>
        <div className="doctorline5"></div>
        <p className='full-doctor-detail5'>Internal Medicine, Critical Care</p>
        <div className="doctor-experience5">Experience:</div>
       <div className="doctor-experience-years5">10+ Years</div>
       <div className="doctor-gender5">Gender:</div>
       <div className="doctor-gender-detail5">Male</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>


      <section>
        <div className="doctor-card6">
          <img className='doctorpic6' src={doctorpic6} alt="" />
          <p className='doctor-name6'>Dr. Anushka Das</p>
        <p className='doctor-detail6'>Director & HOD - Ophthalmology</p>
        <div className="doctorline6"></div>
        <p className='full-doctor-detail6'>Eye Care / Ophthalmology</p>
        <div className="doctor-experience6">Experience:</div>
       <div className="doctor-experience-years6">15+ Years</div>
       <div className="doctor-gender6">Gender:</div>
       <div className="doctor-gender-detail6">Female</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>


      <section>
        <div className="doctor-card7">
          <img className='doctorpic7' src={doctorpic7} alt="" />
          <p className='doctor-name7'>Dr. Saket Bhardwaj</p>
        <p className='doctor-detail7'>Director - Surgical Oncology (Head & Neck)</p>
        <div className="doctorline7"></div>
        <p className='full-doctor-detail7'>Surgical Oncology, Cancer Care / Oncology, Head & Neck Oncology</p>
        <div className="doctor-experience7">Experience:</div>
       <div className="doctor-experience-years7">13+ Years</div>
       <div className="doctor-gender7">Gender:</div>
       <div className="doctor-gender-detail7">Male</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>


      <section>
        <div className="doctor-card8">
          <img className='doctorpic8' src={doctorpic8} alt="" />
          <p className='doctor-name8'>Dr. Kanika Gupta</p>
        <p className='doctor-detail8'>Director Surgical Oncology (Gynae & Robotic Surgery)</p>
        <div className="doctorline8"></div>
        <p className='full-doctor-detail8'>Surgical Oncology, Cancer Care / Oncology, Gynecologic Oncology, Robotic Surgery, Gynaecologic Laparoscopy</p>
        <div className="doctor-experience8">Experience:</div>
       <div className="doctor-experience-years8">8+ Years</div>
       <div className="doctor-gender8">Gender:</div>
       <div className="doctor-gender-detail8">Female</div>

       <button className="bookappointmentbutton" onClick={() => navigate('/bookanappointmentform')}>Book an Appointment</button>
      </div>

      </section>


    </>
  )
}

export default Doctors;






















.group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    width: 25%;
    position: absolute;
    top: 14%;
    right: 10%;
   }
   
   .input {
    width: 100%;
    height: 40px;
    line-height: 28px;
    padding: 5px 1rem;
    padding-left: 2.5rem;
    border: 1px solid #153975;
    border-radius: 8px;
    
    background-color: #fff;
    
    transition: .3s ease;
   }
   
   .input::placeholder {
    color: #153975;
    font-size: 15px;
    font-weight: 545;
   }
   
   .input:focus, input:hover {
    outline: none;
    border-color: #153975;
    background-color: #fff;
    
   }
   
   .doctor-search-icon {
    position: absolute;
    left: 1rem;
    fill: #153975;
    width: 1rem;
    height: 1rem;
   }
   
   
   
   
   
   


.doctor-filters{
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    position: absolute;
    top: 22%;
    left: 8%;

}
.doctor-Available{
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    position: absolute;
    top: 22%;
    left: 25%;
}
   

   
   
   

.doctor-card{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 30%;
    left: 25%;
}
.doctorpic1{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name1{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail1{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail1{
    position: absolute;
    left: 32%;
    top: 30%;
    font-size: 14px;
}
.doctor-experience{
    position: absolute;
    left: 32%;
    top: 58%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years{
    position: absolute;
    left: 49%;
    top: 58%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender{
    position: absolute;
    left: 32%;
    top: 66%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail{
    position: absolute;
    left: 44%;
    top: 66%;
    font-size: 14px;
   font-size: 14px;
}
.bookappointmentbutton{
    position: absolute;
    height: 45px;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 700;
    margin-top: 40%;
    left: 58%;
    color: #fff;
    outline: none;
    border: none;
    padding: 5px 0px;
    width: 38%;
    background-color: rgb(53, 188, 188);
    background-color: #38d2d2;
    background-image: radial-gradient(
        93% 87% at 87% 89%,
        rgba(0, 0, 0, 0.23) 0%,
        transparent 86.18%
      ),
      radial-gradient(
        66% 66% at 26% 20%,
        rgba(255, 255, 255, 0.55) 0%,
        rgba(255, 255, 255, 0) 69.79%,
        rgba(255, 255, 255, 0) 100%
      );
    box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
      inset 0px 3px 9px rgba(255, 255, 255, 0.3),
      inset 0px 1px 1px rgba(255, 255, 255, 0.6),
      inset 0px -8px 36px rgba(0, 0, 0, 0.3),
      inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    cursor: pointer;
}
.bookappointmentbutton:hover,
.bookappointmentbutton:focus {
  background-color: rgb(26, 176, 176);
}










.doctor-card2{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 30%;
    left: 60%;
}
.doctorpic2{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name2{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail2{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail2{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience2{
    position: absolute;
    left: 32%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years2{
    position: absolute;
    left: 49%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender2{
    position: absolute;
    left: 32%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail2{
    position: absolute;
    left: 44%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
}


.doctor-card3{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 75%;
    left: 25%;
}
.doctorpic3{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name3{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail3{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail3{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience3{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years3{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender3{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail3{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}











.doctor-card4{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 75%;
    left: 60%;
}
.doctorpic4{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name4{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail4{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail4{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience4{
    position: absolute;
    left: 32%;
    top: 48%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years4{
    position: absolute;
    left: 49%;
    top: 48%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender4{
    position: absolute;
    left: 32%;
    top: 56%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail4{
    position: absolute;
    left: 44%;
    top: 56%;
    font-size: 14px;
   font-size: 14px;
}









.doctor-card5{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 120%;
    left: 25%;
}
.doctorpic5{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name5{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail5{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail5{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience5{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years5{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender5{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail5{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}











.doctor-card6{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 120%;
    left: 60%;
}
.doctorpic6{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name6{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail6{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail6{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience6{
    position: absolute;
    left: 32%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years6{
    position: absolute;
    left: 49%;
    top: 35%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender6{
    position: absolute;
    left: 32%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail6{
    position: absolute;
    left: 44%;
    top: 43%;
    font-size: 14px;
   font-size: 14px;
}






.doctor-card7{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 165%;
    left: 25%;
}
.doctorpic7{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name7{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail7{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail7{
    position: absolute;
    left: 32%;
    top: 20%;
    font-size: 14px;
}
.doctor-experience7{
    position: absolute;
    left: 32%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years7{
    position: absolute;
    left: 49%;
    top: 41%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender7{
    position: absolute;
    left: 32%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail7{
    position: absolute;
    left: 44%;
    top: 49%;
    font-size: 14px;
   font-size: 14px;
}












.doctor-card8{
    background-color: #fff;
    width: 30%;
    height: 35%;
    position: absolute;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 2px 2px 8px  #00000029;
    top: 165%;
    left: 60%;
}
.doctorpic8{
    width: 27%;
    height: 50%;
    border-radius: 6px;
    position: absolute;
    top: 4%;
    left: 2%;
}
.doctor-name8{
    position: absolute;
    left: 32%;
    top: 0;
    color: #153975;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.doctor-detail8{
    position: absolute;
    left: 32%;
    top: 10%;
    font-size: 14px;
}

.full-doctor-detail8{
    position: absolute;
    left: 32%;
    top: 27%;
    font-size: 14px;
}
.doctor-experience8{
    position: absolute;
    left: 32%;
    top: 55%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-experience-years8{
    position: absolute;
    left: 49%;
    top: 55%;
    font-size: 14px;
   font-size: 14px;
   
}
.doctor-gender8{
    position: absolute;
    left: 32%;
    top: 63%;
    font-size: 14px;
   font-size: 14px;
 font-weight: 500;
}
.doctor-gender-detail8{
    position: absolute;
    left: 44%;
    top: 63%;
    font-size: 14px;
   font-size: 14px;
}

*/