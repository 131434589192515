/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.consultation-para{
padding-top: 5%;
}
.consultation-para2{
  padding-top: 6%;
}
/* From Uiverse.io by satyamchaudharydev */ 
.patient-dashboard-button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color:  #4f46e5;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.patient-dashboard-button-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.patient-dashboard-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.patient-dashboard-button:hover .patient-dashboard-button-icon {
  transform: translate(4px);
}

.patient-dashboard-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.patient-dashboard-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.doctor-visit{
  font-size: 30px;
  font-family: initial;
  color: #464545;
}
.doctor-profile1{
  font-size: 30px;
  font-family: initial;
  color: #464545;
}
.overall{
  font-size: 30px;
  font-family: initial;
  color: #464545;
}
.analytic{
  font-size: 30px;
  font-family: initial;
  color: #464545;
  
}
.dashboard {
  padding: 1rem;
  font-family: sans-serif;
}

.dashboard-grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-left: 240px;
}

.patient-card {
  background: rgb(247, 245, 245);
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.full-width {
  grid-column: span 2;
}



.card-.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-.date-header h2 {
  font-size: 1.25rem;
  font-weight: bold;
}

.performance-indicator {
  color: #10b981;
}

.progress-circle {
  position: relative;
  width: 12rem;
  height: 12rem;
  margin: 0 auto;
}

.progress-circle svg {
  width: 100%;
  height: 100%;
}

.progress-background {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 10;
}

.progress-bar {
  fill: none;
  stroke: #6366f1;
  stroke-width: 10;
  stroke-dasharray: 283;
  stroke-dashoffset: 28;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.progress-score {
  font-size: 2.5rem;
  font-weight: bold;
}

.progress-label {
  font-size: 0.875rem;
  color: #10b981;
}

.progress-description {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.btn-primary {
  display: block;
  width: 100%;
  background-color: #4f46e5;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0.375rem;
  margin-top: 1rem;
  cursor: pointer;
}

.select-dropdown {
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.tab-group {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tab-btn {
  background-color: transparent;
  border: none;
  padding: 0.375rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  cursor: pointer;
}

.tab-btn.active {
  background-color: black;
  color: white;
}

.chart-container {
  height: 16rem;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.profile-image {
  width: 8rem;
  height: 8rem;
border: solid gray 1px;
  border-radius: 10px;
  margin-top: 7%;
}

.profile-details h3 {
  font-weight: bold;
}

.profile-details p {
  font-size: 0.875rem;
  color: #6b7280;
}

.btn-icon {
  background-color: #f3f4f6;
  border: none;
  border-radius: 9999px;
  padding: 0.5rem;
  cursor: pointer;
}

.profile-address,
.profile-contact,
.profile-last-appointment {
  font-size: 0.875rem;
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.appointments-.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.appointments-.date-header h4 {
  font-weight: bold;
}

.date-picker {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.date-item {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
}

.date-item.active {
  background-color: #4f46e5;
  color: white;
}

.appointment-item {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.appointment-indicator {
  width: 0.25rem;
  height: 4rem;
  background-color: #4f46e5;
}

.appointment-details h5 {
  font-weight: bold;
}

.appointment-details p {
  font-size: 0.875rem;
  color: #6b7280;
}

.appointment-time {
  margin-left: auto;
}

/* ... (previous CSS remains unchanged) ... */

.patient-card.full-width {
  display: flex;
  flex-direction: column;
  height: 429px; /* Set a fixed height */
}

.patient-card.full-width .tab-group {
  flex-shrink: 0;
}

.patient-card.full-width .lab-reports-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px; /* Add some padding for the scrollbar */
}

.patient-card.full-width .lab-reports-container::-webkit-scrollbar {
  width: 8px;
}

.patient-card.full-width .lab-reports-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.patient-card.full-width .lab-reports-container::-webkit-scrollbar-thumb {
  background: #6366f1;
  border-radius: 4px;
}

.patient-card.full-width .lab-reports-container::-webkit-scrollbar-thumb:hover {
  background: #4f46e5;
}

.lab-reports-table {
  width: 100%;
  border-collapse: collapse;
}



  @media (min-width: 550px) and (max-width: 768px){
    .consultation-para{
      padding-top: 5%;
      font-size: 23px;
      }
    
  }
/* ... (rest of the CSS remains unchanged) ... */
@media (min-width: 1188px) and (max-width: 1268px){
  .patient-card.full-width {
    height: 456px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 768px) {
  .patient-card.full-width {
    height: 300px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 480px) {
  .patient-card.full-width {
    height: 350px; /* Further adjust height for mobile screens */
  }
}

.lab-reports-table {
  width: 100%;
  border-collapse: collapse;
}

.lab-reports-table th,
.lab-reports-table td {
  text-align: left;
  padding: 0.5rem;
}

.lab-reports-table th {

  font-weight: 400;
  font-size: 20px;
 
  color: #464545;
}


.badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
}

.badge.normal {
  background-color: #d1fae5;
  color: #065f46;
}

.badge.hepatitis {
  background-color: #dbeafe;
  color: #1e40af;
}

.badge.perfect {
  background-color: #d1fae5;
  color: #065f46;
}




.calendarContainer {
  width: 100%;
  max-width: 450px;
  background: rgb(247, 245, 245);
  border-radius: 10px;
  margin: 0 auto;
}

.headerSection {
  display: flex;
  align-items: center;
  padding: 25px 30px 10px;
  justify-content: space-between;
}

.headerSection .navigationIcons {
  display: flex;
}

.iconWrapper {
  height: 38px;
  width: 38px;
  margin: 0 1px;
  cursor: pointer;
  text-align: center;
  line-height: 38px;
  font-size: 1.9rem;
  user-select: none;
  border-radius: 50%;
}

.iconWrapper:last-child {
  margin-right: -10px;
}

.iconWrapper:hover {
  background: #f2f2f2;
}

.dateDisplay {
  font-size: 1.45rem;
  font-weight: 500;
}

.calendarBody {
  padding: 20px;
}

.calendarBody ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-align: center;
}

.calendarBody .dateGrid {
  margin-bottom: 20px;
}

.calendarBody li {
  color: #333;
  width: calc(100% / 7);
  font-size: 1.07rem;
}

.weekDays li {
  font-weight: 500;
  cursor: default;
}

.dateGrid li {
  z-index: 1;
  cursor: pointer;
  position: relative;
  margin-top: 30px;
}

.dateGrid li.inactive {
  color: #aaa;
}

.dateGrid li.active {
  color: #fff;
}

.dateGrid li::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  height: 40px;
  width: 40px;
  z-index: -1;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.dateGrid li.active::before {
  background: #6366f1;
}

.dateGrid li:not(.active):hover::before {
  background: #f2f2f2;
}


@media (min-width: 1064px ) and (max-width: 1189px ) {
 
  .calendarBody li {
  
    width: calc(100% / 7);
    font-size: 13px;
  }
}


@media (max-width: 1064px) {
 
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
  }

  .full-width {
    grid-column: span 2;
  }
  .calendarContainer {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  
  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .full-width {
    grid-column: span 1;
  }

  .calendarContainer {
    grid-column: span 1;
  }

  .headerSection {
    padding: 20px 15px 5px;
  }

  .dateDisplay {
    font-size: 1.2rem;
  }

  .iconWrapper {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 1.5rem;
  }

  .calendarBody {
    padding: 15px;
  }

  .calendarBody li {
    font-size: 0.9rem;
  }

  .dateGrid li::before {
    height: 35px;
    width: 35px;
  }
}

@media (max-width: 480px) {
  .lab-reports-table {
    display: block;
    
  }
  
.lab-reports-table th {

  font-weight: 400;
  font-size: 10px;
 
  color: #464545;
}


  .lab-reports-table thead,
  .lab-reports-table tbody,
  .lab-reports-table th,
  .lab-reports-table td,
  .lab-reports-table tr {
    display: block;
   
  }

  .lab-reports-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .lab-reports-table tr {
  
    margin-bottom: 10px;
  }

  .lab-reports-table td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  .lab-reports-table td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
  }
  .calendarContainer {
    max-width: 100%;
  }

  .headerSection {
    padding: 15px 10px 5px;
  }

  .dateDisplay {
    font-size: 1rem;
  }

  .iconWrapper {
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 1.2rem;
  }

  .calendarBody {
    padding: 10px;
  }

  .calendarBody li {
    font-size: 0.8rem;
  }

  .dateGrid li {
    margin-top: 20px;
  }

  .dateGrid li::before {
    height: 30px;
    width: 30px;
  }
}


@media (max-width: 1064px) {
  .dashboard-grid {
    grid-template-columns: repeat(1);
    margin-left: 23%;
  }


}
@media (max-width: 1024px) {
  .dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0;
  }

  .full-width {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .patient-card {
    margin-bottom: 1rem;
  }

  .profile-info {
    flex-direction: column;
    text-align: center;
  }

  .profile-image {
    margin-bottom: 1rem;
  }

  .chart-container {
    height: 12rem;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: 0.5rem;
  }

  .patient-card {
    padding: 0.75rem;
  }

  .progress-circle {
    width: 8rem;
    height: 8rem;
  }

  .progress-score {
    font-size: 1.5rem;
  }

  .tab-group {
    flex-wrap: wrap;
  }

  .tab-btn {
    flex: 1 0 calc(50% - 0.25rem);
    margin-bottom: 0.5rem;
  }
}


