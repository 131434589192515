.all {
  left: 24%;
  font-size: 180%;
  font-weight: 500;
  font-style: initial;
  margin-top: 2%;
  color: rgb(79, 78, 78);
  font: bold;
  position: absolute;
}
.cancerimg {
  position: absolute;
  top: 160px;
  padding: 20px;
  left: 5%;
  height: 25px;
}
.cancertreat {
  position: absolute;
  top: 158px;
  padding: 20px;
  left: 100px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}
.carimg {
  position: absolute;
  top: 160px;
  padding: 20px;
  left: 22%;
  height: 25px;
}
.cartreat {
  position: absolute;
  top: 158px;
  padding: 20px;
  left: 370px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.coronaryimg{
    position: absolute;
    top: 160px;
    padding: 20px;
    left: 41%;
    height: 25px;
}
.coronarytreat{
    position: absolute;
    top: 158px;
    padding: 20px;
    left: 660px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

}
.heartimg {
    position: absolute;
    top: 210px;
    padding: 20px;
    left: 5%;
    height: 22px;
  }
  .hearttreat {
    position: absolute;
    top: 208px;
    padding: 20px;
    left: 100px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .bariatricimg {
    position: absolute;
    top: 210px;
    padding: 20px;
    left: 345px;
    height: 25px;
  }
  .bariatrictreat{
    position: absolute;
    top: 208px;
    padding: 20px;
    left: 370px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }

  .kidneyimg{
    position: absolute;
    top: 210px;
    padding: 20px;
    left: 41%;
    height: 21px;
}
.kidneytreat{
    position: absolute;
    top: 208px;
    padding: 20px;
    left: 660px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

}
.muscimg {
    position: absolute;
    top: 260px;
    padding: 20px;
    left: 5%;
    height: 25px;
  }
  .musctreat {
    position: absolute;
    top: 258px;
    padding: 20px;
    left: 100px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .boneimg {
    position: absolute;
    top: 260px;
    padding: 20px;
    left: 343px;
    height: 24px;
  }
  .bonetreat{
    position: absolute;
    top: 258px;
    padding: 20px;
    left: 370px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .lapimg{
    position: absolute;
    top: 260px;
    padding: 20px;
    left: 41%;
    height: 21px;
}
.laptreat{
    position: absolute;
    top: 258px;
    padding: 20px;
    left: 660px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

}
.minimg {
    position: absolute;
    top: 310px;
    padding: 20px;
    left: 5%;
    height: 18px;
  }
  .mintreat {
    position: absolute;
    top: 308px;
    padding: 20px;
    left: 100px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .brainimg {
    position: absolute;
    top: 310px;
    padding: 20px;
    left: 343px;
    height: 24px;
  }
  .braintreat{
    position: absolute;
    top: 308px;
    padding: 20px;
    left: 370px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .lithotimg{
    position: absolute;
    top: 310px;
    padding: 20px;
    left: 41%;
    height: 23px;
}
.lithottreat{
    position: absolute;
    top: 308px;
    padding: 20px;
    left: 660px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

}
.neuroimg {
    position: absolute;
    top: 360px;
    padding: 20px;
    left: 5%;
    height: 24px;
  }
  .neurotreat {
    position: absolute;
    top: 358px;
    padding: 20px;
    left: 100px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .braintumorimg {
    position: absolute;
    top: 360px;
    padding: 20px;
    left: 343px;
    height: 22px;
  }
  .braintumortreat{
    position: absolute;
    top: 358px;
    padding: 20px;
    left: 370px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

  }
  .liverimg{
    position: absolute;
    top: 360px;
    padding: 20px;
    left: 41%;
    height: 20px;
}
.livertreat{
    position: absolute;
    top: 358px;
    padding: 20px;
    left: 660px;
    color: rgb(92, 91, 91);
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;

}
.transimg {
  position: absolute;
  top: 410px;
  padding: 20px;
  left: 5%;
  height: 20px;
}
.transtreat {
  position: absolute;
  top: 408px;
  padding: 20px;
  left: 100px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.breastimg {
  position: absolute;
  top: 410px;
  padding: 20px;
  left: 343px;
  height: 25px;
}
.breasttreat{
  position: absolute;
  top: 408px;
  padding: 20px;
  left: 370px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.lungimg{
  position: absolute;
  top: 410px;
  padding: 20px;
  left: 41%;
  height: 25px;
}
.lungtreat{
  position: absolute;
  top: 408px;
  padding: 20px;
  left: 660px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.uroimg {
  position: absolute;
  top: 460px;
  padding: 20px;
  left: 5%;
  height: 19px;
}
.urotreat {
  position: absolute;
  top: 458px;
  padding: 20px;
  left: 100px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.cardiacimg {
  position: absolute;
  top: 460px;
  padding: 20px;
  left: 343px;
  height: 25px;
}
.cardiactreat{
  position: absolute;
  top: 458px;
  padding: 20px;
  left: 370px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.paceimg{
  position: absolute;
  top: 460px;
  padding: 20px;
  left: 41%;
  height: 25px;
}
.pacetreat{
  position: absolute;
  top: 458px;
  padding: 20px;
  left: 660px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.roboticimg {
  position: absolute;
  top: 510px;
  padding: 20px;
  left: 5%;
  height: 19px;
}
.robotictreat {
  position: absolute;
  top: 508px;
  padding: 20px;
  left: 100px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.catimg {
  position: absolute;
  top: 510px;
  padding: 20px;
  left: 343px;
  height: 25px;
}
.cattreat{
  position: absolute;
  top: 508px;
  padding: 20px;
  left: 370px;
  color: rgb(92, 91, 91);
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

}
.treatsingle{
  font-size: 200%;
  position: absolute;
 bottom: 85%;
 right: 70%;


}
.treatmentdefination{
  position: absolute;
  top: 160px;
  padding: 20px;
  left: 1020px;
  color: rgb(92, 91, 91);
  
   
}























.treatmentline{
    width: 1px;
    background-color: rgb(208, 205, 205);
    height: 600px;
    position: absolute;
    top: 120px;
    left: 1000px;
    
    }