@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.doctor-patient-list {
  border-collapse: collapse;
  width: 80%;
  left: 270px;
  position: absolute;
  top: 10%;
  padding: 20px;
}

.table-container {
  overflow-x: auto;
}

.patient-details {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.patient-details th, 
.patient-details td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.patient-details th {
  background-color: #f8f8f8;
  font-weight: bold;
}

.viewdetails {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  margin-top: 0;
  padding: 1px 1px;
  color: #3498db;
 
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-table {
  width: 100%;
  border-collapse: collapse;
}

.modal-table th,
.modal-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.modal-table th {
  font-weight: bold;
  width: 40%;
}

.modal-content button {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.modal-content button:hover {
  background-color: #2980b9;
}

@media screen and (max-width: 1330px) {
  .doctor-patient-list {
    width: 75%;
    left: 280px;
  }
}

@media screen and (max-width: 1180px) {
  .doctor-patient-list {
    width: 75%;
    left: 260px;
  }
}

@media screen and (max-width: 1024px) {
  .patient-details {
    font-size: 14px;
  }
  
  .patient-details th, 
  .patient-details td {
    padding: 10px;
  }
  
  .doctor-patient-list {
    width: 100%;
    left: 0%;
  }
}

@media screen and (max-width: 768px) {
  .patient-details {
    border: 0;
  }
  
  .patient-details thead {
    display: none;
  }
  
  .patient-details tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }
  
  .patient-details td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
    border-bottom: 1px solid #ccc;
  }
 
  .patient-details td:last-child {
    border-bottom: 0;
  }
  
  .patient-details td::before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .modal-content {
    max-width: 90%;
  }
}
































/* 


  import React from 'react'
import "../css/doctorpatientlist.css"
import axios from 'axios'
import { useEffect, useState } from 'react'


const Doctorpatientlist = () => {

const [userlist, setuserlist] = useState('');
const [doctor_id, getdoctor_id] = useState('');




useEffect(()=>{

let id = localStorage.getItem('id');
console.log(localStorage.getItem('id'));
userlistData(id)

},[]);



const userlistData=(doctor_id)=>{
    return axios.get('http://localhost:8080/auth/patientlist/'+doctor_id).then((response)=>{
      setuserlist(response.data.message)
    }) 
}


  return (
    <>
      
<table className='patientdoctor-details'>
  <tr>
    <th>Patient Id</th>
    <th>Name</th>
    <th>Age</th>
    <th>Gender</th>
    <th>Mobile</th>
    <th>Symptoms</th>
    <th>Treatment</th>
  </tr>

{userlist && userlist.map((user)=>
  <tr>
    <td>{user.id}</td>
    <td>{user.name}</td>
    <td>{user.age}</td>
    <td>{user.gender}</td>
    <td>{user.mobile}</td>
    <td>{user.symptoms}</td>
    <td>{user.treatment}</td>
  </tr>
  )}

</table>
    </>
  )
}

export default Doctorpatientlist;





@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.patientdoctor-details {
    border-collapse: collapse;
    width: 75%;
    left: 21%;
    position: absolute;
    top: 16%;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #DDD;
    width: 16%;
    
  }
  tr:hover {background-color: #F7F4F4;} */















