@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

 body{
    font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.patient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 87vh;
  padding: 10px;
}

.patientloginbox {
  background-color: white;
  width: 100%;
  max-width: 790px;
  position: relative;
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  overflow: hidden;
}

.patientformbox {
  width: 50%;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.patientloginimg {
  width: 80%;
  overflow: hidden;
}

.patientloginimg img {
  width: 106%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.patientformbox h3 {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 30px;
  position: relative;
}

.patientformbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(10, 153, 153);
  position: absolute;
  left: 0;
  bottom: 0px;
  border-radius: 20px;
}

.patient-input {
  position: relative;
  margin-bottom: 20px;
}

.patient-input input {
  width: 100%;
  padding: 0px 30px 7px;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 15px;
  outline: none;
}

.fa {
  color: rgb(10, 153, 153);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  font-size: 18px;
}

.fa-eye, .fa-eye-slash {
  right: 5px;
  left: auto;
  cursor: pointer;
}

.patientforgot, .patientsignup {
  margin-bottom: 10px;
  cursor: pointer;
}

.patientloginbtn, .doctorbtn {
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  outline: none;
  border: none;
  padding: 10px;
  width: 100%;
  background-color: #38d2d2;
  background-image: radial-gradient(
    93% 87% at 87% 89%,
    rgba(0, 0, 0, 0.23) 0%,
    transparent 86.18%
  ),
  radial-gradient(
    66% 66% at 26% 20%,
    rgba(255, 255, 255, 0.55) 0%,
    rgba(255, 255, 255, 0) 69.79%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    transition: background-color 0.3s;
 
}

.patientloginbtn:hover, .doctorbtn:hover {
  background-color: rgb(26, 176, 176);
}

.or {
  text-align: center;
   font-weight: bold;
    margin-left: -1%;
    bottom: 10%;
 }
 
.error {
  color: rgb(202, 47, 47);
  font-size: 14px;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  .patientloginimg {
    display: none;
  }
  
  .patientformbox {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .patientloginbox {
    width: 90%;
  }
  
  .patientformbox {
    padding: 30px;
  }
  
  .patientformbox h3 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .patientloginbox {
    width: 100%;
  }
  
  .patientformbox {
    padding: 20px;
  }
  
  .patientformbox h3 {
    font-size: 20px;
  }
  
  .patient-input input {
    font-size: 14px;
  }
} 
























/*


import "../css/patient.css";
import { useNavigate } from 'react-router-dom';
import patientimage from "./image/patientimage.png";
import { useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';

const Patient = () => {
  const navigate = useNavigate();

  const [mobile, mobileSet] = useState('');
  const [password, passwordSet] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const mobileHandler = (event) => {
    mobileSet(event.target.value);
  }

  const passwordHandler = (event) => {
    passwordSet(event.target.value);
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }



  const validateForm = () => {
    let formErrors = {};

    if (!mobile) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(mobile)) {
      formErrors.mobile = "Please enter a valid 10-digit mobile number";
    }

    if (!password) {
      formErrors.password = "Password is required";
    } else if (password.length < 4) {
      formErrors.password = "Password must be at least 6 characters long";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      let patientData = { mobile: mobile, password: password };
      axios.post('http://localhost:8080/auth/login/', patientData)
        .then((response) => {
          console.log(response.data.message.patientdata[0]);
        if (response.data) {
        localStorage.setItem('id', response.data.message.patientdata[0].id);
        localStorage.setItem('mobile', response.data.message.patientdata[0].mobile);
        navigate('/patientdashboard');
        } else {
         navigate('/dashboard');
     }
        })
        .catch((error) => {
          console.error("Login error:", error);
        
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Login failed. Please check your credentials and try again.",
            
          });
        });
        
    }
  }

  return (
    <>
      <form name="loginform" onSubmit={submitHandler}>
        <div className="patientloginbox">
          <div className="patientformbox">
            <h3>Login to Patient</h3>
            <div className="patient-input">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <input 
                type="text" 
                placeholder="Enter your mobile" 
                name="mobile" 
                onChange={mobileHandler}
                id="mobile" 
                value={mobile}
              />
              {errors.mobile && <div className="error">{errors.mobile}</div>}
            </div>
            <div className="patient-input">
              <i className="fa fa-lock" aria-hidden="true"></i>
              <input 
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password" 
                name="password" 
                onChange={passwordHandler}
                id="password" 
                value={password}
              />
              <i 
                className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`} 
                aria-hidden="true"
                onClick={togglePasswordVisibility}
                style={{cursor: 'pointer', position: 'absolute', left: '108%', top: '50%', transform: 'translateY(-50%)'}}
              ></i>
              {errors.password && <div className="error">{errors.password}</div>}
            </div>
            <a className="patientforgot" href="#">Forgot password?</a>
            <div className="patientsignup">
              Don't have an account? <a>Signup now</a>
            </div>
            <section>
              <button className="patientloginbtn" type="submit">Login</button>
            </section>
            {errors.form && <div className="error">{errors.form}</div>}
            <div className="or">Or</div>
            <section>
              <button className="doctorbtn" type="button" onClick={() => navigate('/dashboard')}>As a Doctor</button>
            </section>
          </div>
        </div>
        <figure className="patientimage">
          <img src={patientimage} alt="" />
        </figure>
      </form>
    </>
  )
}

export default Patient;










.patientloginbox {
  background-color: white;
  width: 50em;
  height: 27em;
  position: absolute;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  right: 5px;
  justify-content: space-around;
  box-shadow: 0.188em 0.188em 1.55em rgb(156, 156, 156);
  top: 25%;
  left: 24%;
}

.patientimage {
  width: 11%;
  height: 433px;
  position: absolute;
  left: 656px;
  top: 168px;
}

.patientimage img {
  height: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 15px;
  width: 280%;
}

.patientformbox {
  width: 34%;
  padding: 1px 25px;
  display: flex;
  flex-direction: column;
}

.patientformbox h3 {
  font-size: 28px;
  font-weight: 500;
  position: absolute;
  top: 0;
}

.patientformbox h3::after {
  content: "";
  width: 30px;
  height: 3px;
  background: rgb(10, 153, 153);
  position: absolute;
  left: 0px;
  bottom: 2px;
  border-radius: 20px;
}

.fa {
  color: rgb(10, 153, 153);
  position: absolute;
  top: 10px;
  font-size: 18px;
}

.patient-input {
  width: 84%;
  margin-bottom: 10px;
  position: relative;
  bottom: 12%;
}

.patient-input input {
  display: block;
  width: 100%;
  padding: 10px 20px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(182, 180, 180);
  font-size: 15px;
}

.patientforgot {
  color: black;
  text-decoration: none;
  bottom: 20px;
  position: relative;
}

.patientsignup {
  text-decoration: none;
  bottom: 10px;
  position: relative;
  cursor: pointer;
}

.patientloginbtn {
  position: relative;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 6%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 100%;
  background-color: rgb(53, 188, 188);
  background-color: #38d2d2;
  background-image: radial-gradient(
    93% 87% at 87% 89%,
    rgba(0, 0, 0, 0.23) 0%,
    transparent 86.18%
  ),
  radial-gradient(
    66% 66% at 26% 20%,
    rgba(255, 255, 255, 0.55) 0%,
    rgba(255, 255, 255, 0) 69.79%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

.patientloginbtn:hover,
.loginbtn:focus {
  background-color: rgb(26, 176, 176);
}

.patientloginbtn + p {
  text-align: center;
  font-size: 14px;
}

.patientloginbtn + p a {
  text-decoration: none;
  color: #7227d5;
  font-weight: 500;
}

input::placeholder {
  font-family: "Poppins", sans-serif;
}

.or {
  position: absolute;
  top: 74%;
  left: 19%;
  font-weight: bold;
}

.doctorbtn {
  position: absolute;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  margin-top: 4%;
  color: #fff;
  outline: none;
  border: none;
  padding: 8px 16px;
  width: 34%;
  background-color: rgb(53, 188, 188);
  background-color: #38d2d2;
  background-image: radial-gradient(
    93% 87% at 87% 89%,
    rgba(0, 0, 0, 0.23) 0%,
    transparent 86.18%
  ),
  radial-gradient(
    66% 66% at 26% 20%,
    rgba(255, 255, 255, 0.55) 0%,
    rgba(255, 255, 255, 0) 69.79%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

.doctorbtn:hover,
.doctorbtn:focus {
  background-color: rgb(26, 176, 176);
}


@media (max-width: 1024px) {
  .patientloginbox {
    width: 70%;
    min-height: fit-content;
    top: 30%;
    left: 15%;
  }

  .patientimage {
    width: 15%;
    left: 75px;
    display: none;
  }

  .patientformbox {
    width: 100%;
    padding: 1em;
  }
  .patientformbox h3 {
    font-size: 24px;
  }
  .patientformbox h3::after  {
    content: "";
    width: 30px;
    height: 3px;
    background: rgb(10, 153, 153);
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-radius: 20px;
  }
  
  .patient-input input {
    font-size: 14px;
  }
  .or {
    left: 10%;
  }
  .patientloginbtn {
    font-size: 16px;
    padding: 8px 14px;
    width: 92%;
  }
  .doctorbtn {
    margin-top: 7%;
    font-size: 16px;
    padding: 8px 14px;
    width: 92%;
  }
}

@media (max-width: 768px) {
  .patientloginbox {
    width: 90%;
    min-height: fit-content;
    top: 30%;
    left: 5%;
  }

  .patientimage {
    width: 20%;
    left: 10px;
    top: 120px;
  }

  .patientformbox {
    width: 100%;
    padding: 15px;
  }
  .patientformbox h3  {
    font-size: 20px;
  }
  .patient-input input {
    font-size: 16px;
  }
  .patientloginbtn, .doctorbtn {
    font-size: 14px;
    padding: 8px 12px;
  }

 

  .fa {
    font-size: 16px;
  }
}




*/